import React, { Suspense } from "react";
import { compose } from "redux";
import { Route, Switch, Redirect } from "react-router-dom";
import { Manager } from "../auth/hocs/withAuthRedirect";
import ManagerAppbar from "./components/AppbarContainer";
import NotFound from "../../shared/components/NotFound";
import LoadingScreen from "../../shared/components/Loading";

const SkippedUploadedDocumentsScreen = React.lazy(() =>
    import("./routes/SkippedUploadedDocumentsScreen"),
);

const ManagerScreen = () => (
    <Suspense fallback={<LoadingScreen />}>
        <ManagerAppbar />
        <div className="fill-container">
            <Switch>
                <Route
                    path="/manager/skipped-uploaded-documents"
                    component={SkippedUploadedDocumentsScreen}
                />
                <Redirect exact from="/manager" to="/manager/skipped-uploaded-documents" />
                <Route component={NotFound} />
            </Switch>
        </div>
    </Suspense>
);

export default compose(Manager)(ManagerScreen);
