export enum VatPayerCodeEnum {
    M = "M",
    K = "K",
    I = "I",
}

export enum PaymentMethodEnum {
    BankTransfer = "bank_transfer",
    Cash = "cash",
    CashOnDelivery = "cash_on_delivery",
    Cheque = "cheque",
    Collection = "collection",
    CreditCard = "credit_card",
    Installment = "installment",
    Offseting = "offseting",
    PostalOrderToCash = "postal_order_to_cash",
    PostalOrderToBank = "postal_order_to_bank",
    Proforma = "proforma",
    Sipo = "sipo",
}
