import React from "react";
import PropTypes from "prop-types";
import { Toast, ToastBody, ToastHeader } from "reactstrap";
import withTranslation from "../../hocs/withTranslation";

const ArnoldToast = ({ notification, dismiss, t }) => (
    <Toast fade={false}>
        <ToastHeader toggle={() => dismiss(notification)}>
            {t("messages.notification.title")}
        </ToastHeader>
        <ToastBody>{notification.text || t(notification.translatedText)}</ToastBody>
    </Toast>
);

ArnoldToast.propTypes = {
    notification: PropTypes.shape({
        text: PropTypes.string,
        translatedText: PropTypes.string,
    }).isRequired,
    dismiss: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
};

export default withTranslation(ArnoldToast);
