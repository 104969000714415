import { takeLatest, put, take, call } from "redux-saga/effects";
import {
    END_ACTIVITY,
    END_ACTIVITY_SUCCESS,
    endActivityFailure,
    endActivitySuccess,
    newActivity,
    createActivityFailure,
    createActivitySuccess,
    ActivityStatuses,
    ActivityStatusToType,
} from "../../actions/dataEntering";
import Errors from "../../../../utils/errors";
import ActivityService from "../../../../services/activity";

export function* createActivity(payload) {
    try {
        const result = yield call(ActivityService.create, payload);

        yield put(createActivitySuccess(result));

        return result;
    } catch (e) {
        const errors = new Errors(e);

        yield put(createActivityFailure(errors));

        throw e;
    }
}

function* endActivityFlow(action) {
    const { payload } = action;
    const { activity } = payload;

    const { startedAt, entityId, entityType, status } = activity;

    const type = ActivityStatusToType[status];

    if (!type) {
        return;
    }

    try {
        yield call(createActivity, {
            accountingDocumentId: entityId,
            accountingDocumentType: entityType,
            type,
            startedAt,
            finishedAt: Date.now(),
        });

        yield put(endActivitySuccess(activity));
    } catch (e) {
        const errors = new Errors(e);

        yield put(endActivityFailure(errors));
    }
}

export function* activitiesSaga() {
    while (true) {
        yield put(newActivity());

        const action = yield take(END_ACTIVITY_SUCCESS);

        const {
            payload: { status },
        } = action;

        if ([ActivityStatuses.SKIPPED, ActivityStatuses.PROCESSED].includes(status)) {
            return true;
        }
    }
}

export default [takeLatest(END_ACTIVITY, endActivityFlow)];
