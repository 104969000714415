import merge from "lodash/merge";

const initialState = {
    accounts: {},
    annotations: {},
    skippedUploadedDocuments: {},
    users: {},
};

export default (state = initialState, action) => {
    const { payload } = action;

    if (!payload) {
        return state;
    }

    const { entities, resourceFor } = payload;

    if (entities && !resourceFor) {
        return merge({}, state, entities);
    }

    return state;
};
