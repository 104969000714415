import { call, fork, put, take, all, takeLatest } from "redux-saga/effects";
import * as actions from "../actions/skippings";
import { notify } from "../actions/notifications";
import Api from "./api";
import {
    ENQUEUE,
    enqueueSkippingFailure,
    enqueueSkippingSuccess,
    PROCESS,
    processSkippingFailure,
    processSkippingSuccess,
} from "../actions/skippings";
import AnnotationService from "../../../services/annotation";
import SkippingService from "../../../services/skipping";
import Errors from "../../../utils/errors";
import DocumentService from "../../../services/documents";

/**
 *************************** Subroutines ***********************************
 */

export const fetchSkippedUploadedDocument = Api.call.bind(
    null,
    actions.skippedUploadedDocument,
    SkippingService.get,
);

export function* upsertSkipping(payload) {
    const { documentId, accountId, state } = payload;

    const found = yield call(SkippingService.filter, { accountId, uploadedDocumentId: documentId });

    const skippingId = found.result.items[0];
    let result;

    if (skippingId) {
        result = yield call(SkippingService.update, skippingId, { state });
    } else {
        result = yield call(SkippingService.create, {
            accountId,
            uploadedDocumentId: documentId,
            state,
        });
    }
    return result;
}

export function* handleEnqueue(action) {
    const { payload, meta } = action;

    if (!meta.listenerId) {
        return;
    }

    const {
        data: { content },
        skipping,
    } = payload;

    const { id, account: accountId, uploadedDocumentId: documentId } = skipping;

    try {
        const result = yield call(SkippingService.update, id, { state: "resolved" });

        yield call(AnnotationService.create, { accountId, documentId, content });

        yield put(enqueueSkippingSuccess(result, meta.listenerId));
    } catch (e) {
        const errors = new Errors(e);

        yield all(
            errors
                .generalErrors()
                .map((err) => put(notify({ translatedText: `errors.messages.${err.code}` }))),
        );

        yield put(enqueueSkippingFailure(errors, meta.listenerId));
    }
}

export function* handleProcess(action) {
    const { payload, meta } = action;

    if (!meta.listenerId) {
        return;
    }

    const {
        data: { content },
        skipping,
    } = payload;

    const { id, account: accountId, uploadedDocumentId: documentId } = skipping;

    try {
        yield call(DocumentService.update, accountId, documentId, { state: "processed" });
        const result = yield call(SkippingService.update, id, { state: "resolved" });

        yield call(AnnotationService.create, { accountId, documentId, content });

        yield put(processSkippingSuccess(result, meta.listenerId));
    } catch (e) {
        const errors = new Errors(e);

        yield all(
            errors
                .generalErrors()
                .map((err) => put(notify({ translatedText: `errors.messages.${err.code}` }))),
        );

        yield put(processSkippingFailure(errors, meta.listenerId));
    }
}

/**
 ***************************** WATCHERS ************************************
 */

export function* watchFetchSkipping() {
    while (true) {
        const {
            payload: { id },
        } = yield take(actions.LOAD_SKIPPED_UPLOADED_DOCUMENT);

        yield fork(fetchSkippedUploadedDocument, [id]);
    }
}

const skippedUploadedDocumentsSagas = [
    takeLatest(ENQUEUE, handleEnqueue),
    takeLatest(PROCESS, handleProcess),
    fork(watchFetchSkipping),
];

export default skippedUploadedDocumentsSagas;
