import { call, put } from "redux-saga/effects";

function* callApi(entityActions, serviceFn, params = []) {
    try {
        const payload = yield call(serviceFn, ...params);
        yield put(entityActions.success(payload));
    } catch (error) {
        yield put(entityActions.failure(error));
    }
}

const Api = {
    call: callApi,
};

export default Api;
