export const START_WATCHING_INACTIVITY = "@@arnold/inactivity/START_WATCHING";
export const STOP_WATCHING_INACTIVITY = "@@arnold/inactivity/STOP_WATCHING";

export const startWatchingUserInactivity = () => ({
    type: START_WATCHING_INACTIVITY,
});

export const stopWatchingUserInactivity = () => ({
    type: STOP_WATCHING_INACTIVITY,
});
