import { take, select, put, call, fork } from "redux-saga/effects";
import urlUtils from "../../../utils/urlUtils";

function* handleFetch(key, fn, types, urlParams) {
    yield put({ type: types.REQUEST, key });

    try {
        const payload = yield call(fn, urlParams);
        yield put({
            type: types.SUCCESS,
            key,
            payload,
        });
    } catch (error) {
        yield put({ type: types.FAILURE, key });
    }
}

function* loadEntities(action) {
    const { resource, key, fn, types } = action;

    const paginationParams = (state) => {
        const scope = state.resources;
        const r = Array.isArray(resource)
            ? resource.reduce((acc, k) => acc[k], scope)
            : scope[resource];

        if (r === null || !(typeof r === "object")) return {};

        const nextPageUrl = key ? r[key]?.nextPageUrl : r?.nextPageUrl;
        return nextPageUrl ? urlUtils.urlToParams(nextPageUrl) : {};
    };

    const urlParams = yield select(paginationParams);

    yield fork(handleFetch, key, fn, types, urlParams);
}

function* watchFetchAction() {
    while (true) {
        const action = yield take("@@fetch/REQUEST");

        yield fork(loadEntities, action);
    }
}

const fetchSagas = [fork(watchFetchAction)];

export default fetchSagas;
