import api from "../utils/api";
import { API_ROOT } from "../utils/constants";
import { Schemas } from "../shared/redux/schemas";

const RESOURCE = "accounts";
const ENDPOINT = `${API_ROOT}${RESOURCE}`;

const Account = {
    list: (params, filters) =>
        api.get({
            url: ENDPOINT,
            data: params,
            filters,
            schema: Schemas.ACCOUNT_ARRAY,
        }),
    get: (accountId) =>
        api.get({
            url: `${ENDPOINT}/${accountId}`,
            schema: Schemas.ACCOUNT,
        }),
};

export default Account;
