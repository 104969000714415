// Activity

import { types } from "../../utils/actionCreators";

export const NEW_ACTIVITY = "@@arnold/dataEntering/NEW_ACTIVITY";
export const UPDATE_ACTIVITY = "@@arnold/dataEntering/UPDATE_ACTIVITY";
export const END_ACTIVITY = "@@arnold/dataEntering/END_ACTIVITY";
export const END_ACTIVITY_SUCCESS = "@@arnold/dataEntering/END_ACTIVITY_SUCCESS";
export const END_ACTIVITY_FAILURE = "@@arnold/dataEntering/END_ACTIVITY_FAILURE";
export const CREATE_ACTIVITY_SUCCESS = "@@arnold/dataEntering/CREATE_ACTIVITY_SUCCESS";
export const CREATE_ACTIVITY_FAILURE = "@@arnold/dataEntering/CREATE_ACTIVITY_FAILURE";

// Session
export const START_SESSION = "@@arnold/dataEntering/START_SESSION";
export const START_SESSION_SUCCESS = "@@arnold/dataEntering/START_SESSION_SUCCESS";
export const START_SESSION_FAILURE = "@@arnold/dataEntering/START_SESSION_FAILURE";
export const DATA_ENTERING_EMPTY = "@@arnold/dataEntering/EMPTY";
export const STOP_SESSION = "@@arnold/dataEntering/STOP_SESSION";
export const STOP_SESSION_SUCCESS = "@@arnold/dataEntering/STOP_SESSION_SUCCESS";
export const STOP_SESSION_FAILURE = "@@arnold/dataEntering/STOP_SESSION_FAILURE";

// Commands
export const PROCESS = "@@arnold/dataEntering/commands/PROCESS";
export const PROCESS_SUCCESS = "@@arnold/dataEntering/commands/PROCESS_SUCCESS";
export const PROCESS_FAILURE = "@@arnold/dataEntering/commands/PROCESS_FAILURE";

export const ActivityTypes = {
    SKIPPED: "skipped",
    FINISHED: "finished",
};

export const ActivityStatuses = {
    INITIAL: "INITIAL",
    RUNNING: "RUNNING",
    COMPLETED: "COMPLETED",
    PROCESSED: "PROCESSED",
    SKIPPED: "SKIPPED",
    ENDING: "ENDING",
    ENDED: "ENDED",
};

export const ActivityStatusToType = {
    [ActivityStatuses.PROCESSED]: ActivityTypes.FINISHED,
    [ActivityStatuses.COMPLETED]: ActivityTypes.FINISHED,
    [ActivityStatuses.SKIPPED]: ActivityTypes.SKIPPED,
};

export const newActivity = () => ({
    type: NEW_ACTIVITY,
    payload: {
        status: ActivityStatuses.RUNNING,
        startedAt: Date.now(),
    },
});

export const createActivitySuccess = (payload) => ({
    type: CREATE_ACTIVITY_SUCCESS,
    payload,
});

export const createActivityFailure = (errors) => ({
    type: CREATE_ACTIVITY_FAILURE,
    payload: { errors },
});

export const updateActivity = (payload) => ({
    type: UPDATE_ACTIVITY,
    payload,
});

export const endActivity = (payload) => ({
    type: END_ACTIVITY,
    payload,
});

export const endActivitySuccess = (payload) => ({
    type: END_ACTIVITY_SUCCESS,
    payload,
});

export const endActivityFailure = (errors) => ({
    type: END_ACTIVITY_FAILURE,
    payload: {
        errors,
    },
});

export const dataEnteringEmpty = () => ({
    type: DATA_ENTERING_EMPTY,
});

export const startSession = () => ({
    type: START_SESSION,
});

export const startSessionSuccess = (payload) => ({
    type: START_SESSION_SUCCESS,
    payload,
});

export const startSessionFailure = (errors) => ({
    type: START_SESSION_FAILURE,
    payload: errors,
});

export const stopSession = () => ({
    type: STOP_SESSION,
});

export const stopSessionSuccess = (payload) => ({
    type: STOP_SESSION_SUCCESS,
    payload,
});

export const stopSessionFailure = (errors) => ({
    type: STOP_SESSION_FAILURE,
    payload: errors,
});

export const SKIP = types("dataEntering/commands", "SKIP");

// Commands
export const skipCommandSuccess = (payload, listenerId) => ({
    type: SKIP.SUCCESS,
    payload,
    meta: {
        listenerId,
    },
});

export const skipCommandFailure = (payload, listenerId) => ({
    type: SKIP.FAILURE,
    payload,
    error: true,
    meta: {
        listenerId,
    },
});

export const processCommand = (dataEntering, activity) => ({
    type: PROCESS,
    payload: {
        dataEntering,
        activity,
    },
});

export const processCommandSuccess = (payload) => ({
    type: PROCESS_SUCCESS,
    payload,
});

export const processCommandFailure = (errors) => ({
    type: PROCESS_FAILURE,
    payload: errors,
});
export const SWITCH_FORM = "SWITCH_FORM";
export const switchForm = (payload) => ({
    type: SWITCH_FORM,
    payload,
});
export const REMOVE_FORM = "REMOVE_FORM";
export const removeForm = () => ({
    type: REMOVE_FORM,
});
export const FormTypes = {
    PURCHASE_INVOICE: "purchase_invoice",
    SALES_INVOICE: "sales_invoice",
    PETTY_CASH_DISBURSTMENT: "petty_cash_disburstment",
    PETTY_CASH_INCOME: "petty_cash_income",
    CATEGORIZE: "categorize",
};
