import React, { useState } from "react";
import { InputGroup, Button } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import Input from "../../../../shared/components/FormInputs/InputWrapper";

const PasswordTextField = React.forwardRef((props, ref) => {
    const [showPassword, setShowPassword] = useState(false);

    const handleShowPasswordClick = () => setShowPassword(!showPassword);

    const handleShowPasswordMouseDown = (event) => event.preventDefault();

    return (
        <InputGroup>
            <Input ref={ref} {...props} type={showPassword ? "text" : "password"} />
            <Button onClick={handleShowPasswordClick} onMouseDown={handleShowPasswordMouseDown}>
                <FontAwesomeIcon icon={showPassword ? faEyeSlash : faEye} fixedWidth />
            </Button>
        </InputGroup>
    );
});

export default PasswordTextField;
