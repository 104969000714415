import { put, call, all, takeLatest } from "redux-saga/effects";

import {
    ActivityStatuses,
    PROCESS,
    processCommandFailure,
    processCommandSuccess,
    SKIP,
    skipCommandFailure,
    skipCommandSuccess,
} from "../../actions/dataEntering";
import Errors from "../../../../utils/errors";
import DocumentService from "../../../../services/documents";
import AnnotationService from "../../../../services/annotation";
import { notify } from "../../actions/notifications";
import { upsertSkipping } from "../skippings";

function* handleProcessCommand(action) {
    const { dataEntering, activity } = action.payload;

    const { accountId, documentId } = dataEntering;

    const { status } = activity;

    if (status !== ActivityStatuses.COMPLETED) {
        return;
    }

    try {
        const result = yield call(DocumentService.update, accountId, documentId, {
            state: "processed",
        });

        yield put(processCommandSuccess(result));
    } catch (e) {
        const errors = new Errors(e);

        yield put(processCommandFailure(errors));
    }
}

function* handleSkipCommand(action) {
    const { payload, meta } = action;

    if (!meta.listenerId) {
        return;
    }

    const {
        data: { content },
        dataEntering: { accountId, documentId },
    } = payload;

    try {
        const result = yield call(upsertSkipping, { accountId, documentId, state: "unresolved" });

        yield call(AnnotationService.create, { accountId, documentId, content });

        yield put(skipCommandSuccess(result, meta.listenerId));
    } catch (e) {
        const errorsParser = new Errors(e);

        const notifications = errorsParser
            .generalErrors()
            .map((err) => put(notify({ translatedText: `errors.messages.${err.code}` })));

        yield all([put(skipCommandFailure(errorsParser, meta.listenerId)), ...notifications]);
    }
}

const sagas = [
    takeLatest(SKIP.REQUEST, handleSkipCommand),
    takeLatest(PROCESS, handleProcessCommand),
];

export default sagas;
