import { fetchTypes } from "../../utils/actionCreators";

export const ARES = fetchTypes("ARES");
export const searchAres = (companyNumber) => ({
    type: ARES.REQUEST,
    payload: { companyNumber },
});
export const searchAresSuccess = (payload) => ({
    type: ARES.SUCCESS,
    payload,
});
export const searchAresFailure = (errors) => ({
    type: ARES.FAILURE,
    errors,
});
export const SEARCH_ARES_CLEAR = "SEARCH_ARES_CLEAR";
export const searchAresClear = () => ({
    type: SEARCH_ARES_CLEAR,
});
