import React from "react";
import { Spinner } from "reactstrap";
import "./index.scss";

const LoadingScreen = () => (
    <div className="loading--container">
        <Spinner />
    </div>
);

export default LoadingScreen;
