import { createApi } from "@reduxjs/toolkit/query/react";
import { arnoldBaseQuery } from "../baseQuery";

export const DATA_ENTERING_API_REDUCER_KEY = "dataEnteringApi";
export const api = createApi({
    reducerPath: DATA_ENTERING_API_REDUCER_KEY,
    baseQuery: arnoldBaseQuery,
    tagTypes: [],
    endpoints: () => ({}),
});
