import React from "react";
import PropTypes from "prop-types";

import "./index.scss";

export const Container = ({ children }) => <div className="appbar-container">{children}</div>;

export const Left = ({ children }) => <div className="appbar-container--left">{children}</div>;

export const Right = ({ children }) => <div className="appbar-container--right">{children}</div>;

Container.propTypes = {
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
};

Right.propTypes = {
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
};

Left.propTypes = {
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
};

export default Container;
