import api from "../utils/api";
import { API_ROOT } from "../utils/constants";
import { Schemas } from "../shared/redux/schemas";

const RESOURCE = "skipped_uploaded_documents";
const ENDPOINT = `${API_ROOT}${RESOURCE}`;

const SkippedUploadedDocument = {
    get: (id) => api.get({ url: `${ENDPOINT}/${id}`, schema: Schemas.SKIPPED_UPLOADED_DOCUMENT }),
    getUploadedDocumentData: (skippedUploadedDocumentId) =>
        api.get({
            url: `${ENDPOINT}/${skippedUploadedDocumentId}/uploaded_document`,
            schema: Schemas.UPLOADED_DOCUMENT,
        }),
    filter: (params, filters) =>
        api.get({
            url: ENDPOINT,
            data: params,
            filters,
            schema: Schemas.SKIPPED_UPLOADED_DOCUMENT_ARRAY,
        }),
    create: (data) => api.post({ url: ENDPOINT, schema: Schemas.SKIPPED_UPLOADED_DOCUMENT, data }),
    update: (id, data) =>
        api.patch({ url: `${ENDPOINT}/${id}`, schema: Schemas.SKIPPED_UPLOADED_DOCUMENT, data }),
    annotate: (id, data) =>
        api.post({ url: `${ENDPOINT}/${id}/annotations`, schema: Schemas.ANNOTATION, data }),
};

export default SkippedUploadedDocument;
