import { api as accountingApi } from "../api";
import { CollectionResponse } from "../types";
import { MyCompany, MyCompanyGetLatestParams, MyCompanySearchParams } from "./types";
import { ArnoldBaseQueryError } from "../../baseQuery";

const isLater = (a: MyCompany, b: MyCompany) => {
    const aDate = new Date(a.validFrom);
    const bDate = new Date(b.validFrom);
    return aDate > bDate;
};

export const myCompaniesApi = accountingApi.injectEndpoints({
    endpoints: (build) => ({
        getMyCompanies: build.query<CollectionResponse<MyCompany>, MyCompanySearchParams>({
            query: ({ accountId, ...params }) => ({
                url: `api/v1/accounting/${accountId}/my_companies`,
                params,
            }),
        }),
        getLatestMyCompany: build.query<MyCompany, MyCompanyGetLatestParams>({
            async queryFn(arg, _queryApi, _extraOptions, fetchWithBQ) {
                const { accountId, records = [], page = 1 } = arg;
                const result = await fetchWithBQ({
                    url: `api/v1/accounting/${accountId}/my_companies`,
                    params: { page, perPage: 100 },
                });

                if (result.error) {
                    return { error: result.error as ArnoldBaseQueryError };
                }

                const { items, meta } = result.data as CollectionResponse<MyCompany>;

                records.push(...items);

                if (page < meta.last) {
                    return this.queryFn(
                        { ...arg, page: page + 1, records },
                        _queryApi,
                        _extraOptions,
                        fetchWithBQ,
                    );
                }

                const latest = records.reduce((m, v) => (isLater(v, m) ? v : m));

                return { data: latest as MyCompany };
            },
        }),
    }),
});

export const { useGetMyCompaniesQuery, useGetLatestMyCompanyQuery } = myCompaniesApi;
