const connection = function createWebSocket(
    authenticationToken,
    webSocketServerUrl = process.env.ACTION_CABLE_URL,
) {
    if (!webSocketServerUrl) {
        throw new Error("WebSocket server URL is not set!");
    }

    if (!authenticationToken) {
        return new WebSocket(webSocketServerUrl);
    }

    return new WebSocket(`${webSocketServerUrl}?token=${authenticationToken}`);
};

export default connection;
