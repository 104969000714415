import React, { Ref } from "react";
import { Input, InputProps } from "reactstrap";

const InputWrapper = React.memo(
    React.forwardRef((props: InputProps, ref: Ref<HTMLInputElement>) => (
        <Input {...props} innerRef={ref} />
    )),
);

export default InputWrapper;
