import { fork, put, take, race, spawn, delay } from "redux-saga/effects";
import {
    ENQUEUE_NOTIFICATION,
    CLEAR_NOTIFICATION,
    clearNotification,
} from "../actions/notifications";

const actionToTake = (action) => action.type === ENQUEUE_NOTIFICATION && action.payload.autoClear;

export function* removeNotificationAfterElapsedTime(waitFor, { payload }) {
    const { timedOut } = yield race({
        timedOut: delay(waitFor),
        _: take((action) => action.type === CLEAR_NOTIFICATION && action.payload.id === payload.id),
    });

    if (timedOut) {
        yield put(clearNotification(payload));
    }
}

export function* watchAutoRemoveNotification() {
    while (true) {
        const action = yield take(actionToTake);

        yield spawn(removeNotificationAfterElapsedTime, 2500, action);
    }
}

const notificationsSagas = [fork(watchAutoRemoveNotification)];

export default notificationsSagas;
