import dompurify from "dompurify";

function countryToFlag(isoCode: string) {
    return typeof String.fromCodePoint !== "undefined"
        ? isoCode
              .toUpperCase()
              .replace(/./g, (char) => String.fromCodePoint(char.charCodeAt(0) + 127397))
        : isoCode;
}

const HtmlUtils = {
    sanitize: dompurify.sanitize,
    countryToFlag: countryToFlag,
};

export default HtmlUtils;
