import {
    BaseQueryFn,
    FetchArgs,
    fetchBaseQuery,
    FetchBaseQueryError,
} from "@reduxjs/toolkit/query/react";
import { camelizeKeys, decamelizeKeys } from "humps";
import { getAuthToken } from "../utils/auth";
import { camelCase } from "lodash";

function parsePagination(data: {
    meta?: { pagination?: { first?: string; last?: string; next?: string; previous?: string } };
}): { first?: number; last?: number; next?: number; previous?: number } {
    const pagination = data?.meta?.pagination;

    if (!pagination) return {};

    return Object.keys(pagination).reduce((acc, key) => {
        const pageNumber = new URL(pagination[key]).searchParams.get("page");

        // eslint-disable-next-line no-param-reassign
        acc[key] = parseInt(pageNumber, 10);

        return acc;
    }, {});
}

const fetchArnoldBaseQuery = fetchBaseQuery({
    baseUrl: "/",
    credentials: "include",
    prepareHeaders: (headers) => {
        const token = getAuthToken();

        if (token) {
            headers.set("Authorization", `Bearer ${token}`);
        }

        headers.set("Accept", "application/json");
        headers.set("Content-Type", "application/json");

        return headers;
    },
});

export interface ErrorSource {
    pointer: string;
}

export interface Error {
    status: number;
    code: string;
    source?: ErrorSource;
    detail: string;
}

export type ArnoldBaseQueryError = {
    status: number | string;
    error: string;
    data?: {
        errors?: Error[];
    };
};

const serializeError = (error: FetchBaseQueryError): ArnoldBaseQueryError => {
    switch (error.status) {
        case "FETCH_ERROR":
        case "PARSING_ERROR":
        case "CUSTOM_ERROR": {
            return {
                status: error.status,
                error: error.error,
            };
        }
        default: {
            const { errors = [] } = error.data as { errors: Error[] };

            const parsedErrors: Error[] = errors.map((error: Error) => {
                if ("source" in error && "pointer" in error.source) {
                    const { pointer } = error.source;

                    const path = pointer.split(".");

                    path[path.length - 1] = camelCase(path[path.length - 1]);

                    return {
                        ...error,
                        source: {
                            pointer: path.join("."),
                        },
                    };
                }

                return error;
            });

            return {
                status: error.status,
                error: "Arnold API Error",
                data: {
                    errors: parsedErrors,
                },
            };
        }
    }
};

const serializeData = (data: unknown) => {
    const camelizedData = camelizeKeys(data);

    return {
        ...camelizedData,
        meta: {
            ...parsePagination(camelizedData),
        },
    };
};

export const arnoldBaseQuery: BaseQueryFn<FetchArgs, unknown, ArnoldBaseQueryError> = async (
    args,
    api,
    extraOptions,
) => {
    const preparedArgs: FetchArgs = {
        ...args,
        body: args.body ? decamelizeKeys(args.body) : undefined,
        params: args.params ? decamelizeKeys(args.params) : undefined,
    };

    const { data, error, meta } = await fetchArnoldBaseQuery(preparedArgs, api, extraOptions);

    return {
        data: data ? serializeData(data) : undefined,
        error: error ? serializeError(error) : undefined,
        meta,
    };
};
