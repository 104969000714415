import { api as accountingApi } from "../api";
import { CollectionResponse } from "../types";
import {
    Department,
    DepartmentGetAllParams,
    DepartmentGetDefaultParams,
    DepartmentGetDefaultResponse,
    DepartmentSearchParams,
} from "./types";
import { ArnoldBaseQueryError } from "../../baseQuery";

export const departmentsApi = accountingApi.injectEndpoints({
    endpoints: (build) => ({
        getDepartments: build.query<CollectionResponse<Department>, DepartmentSearchParams>({
            query: ({ accountId, ...params }) => ({
                url: `api/v1/accounting/${accountId}/departments`,
                params,
            }),
        }),
        getDefaultDepartment: build.query<DepartmentGetDefaultResponse, DepartmentGetDefaultParams>(
            {
                async queryFn(arg, _queryApi, _extraOptions, fetchWithBQ) {
                    const page = arg.page || 1;
                    const result = await fetchWithBQ({
                        url: `api/v1/accounting/${arg.accountId}/departments`,
                        params: { page, perPage: 100 },
                    });

                    if (result.error) {
                        return { error: result.error as ArnoldBaseQueryError };
                    }

                    const { items, meta } = result.data as CollectionResponse<Department>;

                    const defaultDepartment = items.find((item) => item.default);

                    if (defaultDepartment) {
                        return { data: defaultDepartment as Department };
                    }

                    if (page < meta.last) {
                        return this.queryFn(
                            { ...arg, page: page + 1 },
                            _queryApi,
                            _extraOptions,
                            fetchWithBQ,
                        );
                    }

                    return { data: null };
                },
            },
        ),
        getAllDepartments: build.query<Department[], DepartmentGetAllParams>({
            async queryFn(arg, _queryApi, _extraOptions, fetchWithBQ) {
                const { accountId, records = [], page = 1 } = arg;
                const result = await fetchWithBQ({
                    url: `api/v1/accounting/${accountId}/departments`,
                    params: { page, perPage: 100 },
                });

                if (result.error) {
                    return { error: result.error as ArnoldBaseQueryError };
                }

                const { items, meta } = result.data as CollectionResponse<Department>;

                records.push(...items);

                if (page < meta.last) {
                    return this.queryFn(
                        { ...arg, page: page + 1, records },
                        _queryApi,
                        _extraOptions,
                        fetchWithBQ,
                    );
                }

                return { data: records as Department[] };
            },
        }),
    }),
});

export const { useGetDepartmentsQuery, useGetDefaultDepartmentQuery, useGetAllDepartmentsQuery } =
    departmentsApi;
