import React from "react";
import ReactDOM from "react-dom";
import * as Sentry from "@sentry/react";
import { Provider } from "react-redux";
// @ts-expect-error translations is virtual webpack module
import translations from "translations";
import App from "./App";
import { store } from "./shared/redux/configureStore";
import { resolveLocale } from "./utils/locale";
import LocaleProvider from "./shared/providers/I18nProvider";

Sentry.init({
    dsn: process.env.SENTRY_CLIENT_DSN,
    release: process.env.SENTRY_RELEASE,
    environment: process.env.RAILS_ENV,
    autoSessionTracking: true,
    integrations: [new Sentry.BrowserTracing()],
    tracesSampleRate: 0.5,
});

document.addEventListener("DOMContentLoaded", () => {
    ReactDOM.render(
        <Provider store={store}>
            <LocaleProvider defaultLocale={resolveLocale()} messages={translations}>
                <React.StrictMode>
                    <App />
                </React.StrictMode>
            </LocaleProvider>
        </Provider>,
        document.getElementById("root"),
    );
});
