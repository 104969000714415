import * as actions from "../actions/modal";

const initialState = {
    modalType: null,
    modalProps: {},
};

export default (state = initialState, { type, modalProps, modalType }) => {
    switch (type) {
        case actions.SHOW_MODAL:
            return {
                modalProps,
                modalType,
            };
        case actions.HIDE_MODAL:
            return initialState;
        default:
            return state;
    }
};
