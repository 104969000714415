import React from "react";
import { I18nContext } from "../../providers/I18nProvider/I18nContext";

const translate = (WrappedComponent) => (props) => (
    <I18nContext.Consumer>
        {({ t, translateWithFallback, has, locale }) => (
            <WrappedComponent {...props} {...{ t, translateWithFallback, has, locale }} />
        )}
    </I18nContext.Consumer>
);

export default translate;
