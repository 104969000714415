import { createApi } from "@reduxjs/toolkit/query/react";

import { arnoldBaseQuery } from "../baseQuery";

export const ACCOUNTING_API_REDUCER_KEY = "accountingApi";
export const api = createApi({
    reducerPath: ACCOUNTING_API_REDUCER_KEY,
    baseQuery: arnoldBaseQuery,
    tagTypes: ["PurchaseInvoice", "SalesInvoice", "Contact", "Pairing"],
    endpoints: () => ({}),
});
