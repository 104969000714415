import { v4 as uuidv4 } from "uuid";

export default function createListener() {
    const listeners = {};
    let dispatch;

    const middleware = (store) => {
        dispatch = store.dispatch;

        return (next) => (action) => {
            const { meta } = action;

            if (meta && meta.listenerId) {
                listeners[meta.listenerId](action);
            }

            return next(action);
        };
    };

    const promiseOnAction = (types) => {
        const listenerId = uuidv4();

        const unsubscribe = () => {
            delete listeners[listenerId];
        };

        const promise = (payload) =>
            new Promise((resolve, reject) => {
                listeners[listenerId] = (action) => {
                    switch (action.type) {
                        case types.success: {
                            unsubscribe();
                            resolve(action.payload);
                            break;
                        }
                        case types.failure: {
                            unsubscribe();
                            reject(action.payload);
                            break;
                        }
                        default:
                            break;
                    }
                };

                dispatch({ type: types.request, payload, meta: { listenerId } });
            });

        return { promise, unsubscribe };
    };

    return { middleware, promiseOnAction };
}
