import React from "react";
import PropTypes from "prop-types";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBan } from "@fortawesome/free-solid-svg-icons";
import translate from "../../hocs/withTranslation";
import "./index.scss";
import CenteredContainer from "../CenteredContainer";

const NotFoundScreen = ({ t }) => (
    <CenteredContainer>
        <div className="not-found">
            <FontAwesomeIcon icon={faBan} size="8x" />
            <h2>{t("messages.not_found")}</h2>
        </div>
    </CenteredContainer>
);

NotFoundScreen.propTypes = {
    t: PropTypes.func.isRequired,
};

export default translate(NotFoundScreen);
