import { call, fork, put, take, cancel, all } from "redux-saga/effects";
import { normalize } from "normalizr";
import { push } from "redux-first-history";
import * as authActions from "../actions/auth";
import { getAuthToken, removeAuthToken, setAuthToken } from "../../../utils/auth";
import Auth from "../../../services/auth";
import User from "../../../services/user";
import { Schemas } from "../schemas";
import { notify } from "../actions/notifications";
import Errors from "../../../utils/errors";

export function* logout() {
    try {
        yield call(Auth.logout);

        yield put(authActions.logoutSuccess());
    } catch (error) {
        yield put(authActions.logoutFailure(error));
    }
}

export function* authorize() {
    const token = getAuthToken();

    if (token) {
        try {
            const user = yield call(User.current);
            const normalized = normalize(user, Schemas.USER);

            yield put(authActions.authenticateSuccess(normalized));
        } catch (error) {
            yield call(removeAuthToken);
            yield put(push("/login"));

            yield put(authActions.authenticateFailure(error));
        }
    }
}

export function* logoutFlow() {
    while (true) {
        yield take(authActions.LOGOUT_REQUEST);

        yield fork(logout);

        const action = yield take([authActions.LOGOUT_SUCCESS, authActions.LOGOUT_FAILURE]);

        if (action.type === authActions.LOGOUT_FAILURE) {
            const errorsParser = new Errors(action.errors);

            const errorActions = errorsParser.errors.map((err) =>
                put(notify({ translatedText: `errors.messages.${err.code}` })),
            );

            yield all(errorActions);
        }

        yield call(removeAuthToken);
        yield put(push("/login"));

        yield put(notify({ translatedText: "messages.auth.logged_out", autoClear: true }));
    }
}

export function* login(action) {
    const { payload, meta } = action;

    if (!meta.listenerId) {
        return;
    }

    try {
        const { token } = yield call(Auth.login, payload, {
            Accept: "application/json",
            "Content-Type": "application/json",
        });

        yield put(authActions.loginSuccess(token, meta.listenerId));
        yield call(setAuthToken, token);
        yield call(authorize);
        yield put(push("/"));

        yield put(notify({ translatedText: "messages.auth.logged_in", autoClear: true }));
    } catch (error) {
        const errorsParser = new Errors(error);

        const errorActions = errorsParser.errors.map((err) =>
            put(notify({ translatedText: `errors.messages.${err.code}` })),
        );

        yield all([
            yield put(authActions.loginFailure(errorsParser, meta.listenerId)),
            ...errorActions,
        ]);
    }
}

export function* loginFlow() {
    while (true) {
        const loginReq = yield take(authActions.LOGIN.REQUEST);

        const loginTask = yield fork(login, loginReq);

        const action = yield take([authActions.LOGOUT_REQUEST, authActions.LOGIN.FAILURE]);

        // Cancel login task if LOGOUT action dispatched before its completion
        if (action.type === authActions.LOGOUT_REQUEST) {
            yield cancel(loginTask);
        }
    }
}

const authSagas = [fork(authorize), fork(loginFlow), fork(logoutFlow)];

export default authSagas;
