import React, { useCallback } from "react";
import { DropdownItem, UncontrolledButtonDropdown, DropdownToggle, DropdownMenu } from "reactstrap";
import { useSelector, useDispatch } from "react-redux";
import PropTypes from "prop-types";
import translate from "../../../hocs/withTranslation";
import LocaleSwitch from "../../LocaleSwitch";
import { authenticatedUser } from "../../../redux/selectors/auth";
import { logout } from "../../../redux/actions/auth";

const Bar = ({ t }) => {
    const { username } = useSelector((state) => authenticatedUser(state));
    const dispatch = useDispatch();

    const handleLogout = useCallback(() => dispatch(logout()), [dispatch]);

    return (
        <UncontrolledButtonDropdown className="h-100">
            <DropdownToggle color="primary" title={t("user_menu.dropdown.title")} caret>
                {username}
            </DropdownToggle>
            <DropdownMenu end>
                <LocaleSwitch title={t("user_menu.locale_switch.title")} />
                <DropdownItem divider />
                <DropdownItem onClick={handleLogout} title={t("actions.logout")}>
                    {t("actions.logout")}
                </DropdownItem>
            </DropdownMenu>
        </UncontrolledButtonDropdown>
    );
};

Bar.propTypes = {
    t: PropTypes.func.isRequired,
};

export default translate(Bar);
