import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Modals from "../../../features/dataEntering/routes/dataEntering/components/Modals";
import { hideModal } from "../../redux/actions/modal";
import SkipModal from "../../../features/dataEntering/routes/dataEntering/components/Skip/Modal";
import ResolveSkippingModal from "../../../features/manager/routes/SkippedUploadedDocumentsScreen/components/ResolveSkipping/Modal";

const MODAL_COMPONENTS = {
    SKIP_DATA_ENTERING: SkipModal,
    RESOLVE_SKIPPING: ResolveSkippingModal,
    ASSIGNED_ACCOUNT_DETAIL: Modals.AssignedAccountDetailModal,
};

const ModalContainer = ({ modalType, modalProps, hideModalConnect }) => {
    if (!modalType) {
        return null;
    }

    const SpecifiedModal = MODAL_COMPONENTS[modalType];

    if (!SpecifiedModal) {
        return null;
    }

    return <SpecifiedModal {...modalProps} hideModal={hideModalConnect} />;
};

export const mapDispatchToProps = {
    hideModalConnect: hideModal,
};

export const mapStateToProps = ({ modal }) => ({
    ...modal,
});

ModalContainer.defaultProps = {
    modalType: null,
    modalProps: {},
};

ModalContainer.propTypes = {
    modalType: PropTypes.string,
    modalProps: PropTypes.shape({}),
    hideModalConnect: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(ModalContainer);
