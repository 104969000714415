import api from "../utils/api";
import { API_ROOT } from "../utils/constants";

const RESOURCE = "authentication";
const ENDPOINT = `${API_ROOT}${RESOURCE}`;

const Auth = {
    login: (credentials, headers) => api.post({ url: ENDPOINT, data: credentials, headers }),
    logout: () => api.delete({ url: ENDPOINT }),
};

export default Auth;
