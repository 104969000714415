import { PaymentMethodEnum } from "../services/accounting/enums";

export const API_ROOT = "/api/v1/";

export const channels = {
    accounts: ["AccountUpdatesChannel"],
    dataEntering: ["DataEntryChannel"],
};

export const defaults = {
    contact: {
        country: "CZ",
    },
    invoices: {
        country: "CZ",
        currency: "CZK",
        paymentMethod: PaymentMethodEnum.BankTransfer,
        type: "standard",
    },
};

export default {
    channels,
    defaults,
};
