import { schema } from "normalizr";
import userSchema from "./user";

const accountSchema = new schema.Entity(
    "accounts",
    {
        assignee: userSchema,
    },
    {
        idAttribute: (account) => account.id,
    },
);

export default accountSchema;
