import { filter } from "lodash";
import {
    ENQUEUE_NOTIFICATION,
    CLEAR_NOTIFICATION,
    CLEAR_ALL_NOTIFICATIONS,
} from "../actions/notifications";

const initialState = [];

const notificationsReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case ENQUEUE_NOTIFICATION:
            return [payload, ...state];
        case CLEAR_NOTIFICATION:
            return filter(state, ({ id }) => id !== payload.id);
        case CLEAR_ALL_NOTIFICATIONS:
            return initialState;
        default:
            return state;
    }
};

export default notificationsReducer;
