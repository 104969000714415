import React from "react";
import PropTypes from "prop-types";
import { Form, Button, FormFeedback, FormGroup, Label } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSave, faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import FormToolbar from "../FormToolbar";
import Input from "../FormInputs/InputWrapper";
import contract from "./contract";
import useTranslation from "../../hooks/useTranslation";

const AnnotationForm = ({ onSubmit, onSubmitSuccess, cancel }) => {
    const { t } = useTranslation();
    const {
        register,
        handleSubmit,
        setError,
        formState: { errors, isSubmitting },
    } = useForm({ resolver: yupResolver(contract) });

    const submit = handleSubmit(async (data) => {
        try {
            const result = await onSubmit(data);

            onSubmitSuccess(result);
        } catch (e) {
            e.parsedErrors().forEach((err) =>
                setError(err.source.pointer, {
                    type: err.code,
                    message: err.detail,
                }),
            );
        }
    });

    return (
        <Form onSubmit={submit}>
            <FormGroup>
                <Label for="content" className="label-sm">
                    {t("attributes.annotation.content")}
                </Label>
                <Input
                    {...register("content")}
                    type="textarea"
                    invalid={!!errors.content}
                    autoFocus
                    rows={5}
                    autoComplete="off"
                />
                {errors.content && (
                    <FormFeedback>{t(`errors.messages.${errors.content.type}`)}</FormFeedback>
                )}
            </FormGroup>
            <FormToolbar>
                <Button onClick={cancel}>
                    <FontAwesomeIcon icon={faTimesCircle} /> {t("actions.cancel")}
                </Button>
                <Button color="primary" id="btnCreate" type="submit" disabled={isSubmitting}>
                    <FontAwesomeIcon icon={faSave} /> {t("actions.continue")}
                </Button>
            </FormToolbar>
        </Form>
    );
};

AnnotationForm.defaultProps = {
    onSubmitSuccess: () => {},
};

AnnotationForm.propTypes = {
    onSubmit: PropTypes.func.isRequired,
    onSubmitSuccess: PropTypes.func,
    cancel: PropTypes.func.isRequired,
};

export default AnnotationForm;
