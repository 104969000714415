import { createAsyncThunk } from "@reduxjs/toolkit";
import { createFormSlice } from "../../../shared/slice";
import { myCompaniesApi } from "../../../../../../../../../services/accounting/myCompany/api";
import { countriesApi } from "../../../../../../../../../services/accounting/country/api";
import { defaults } from "../../../../../../../../../utils/constants";
import { departmentsApi } from "../../../../../../../../../services/accounting/department/api";
import dateFormat from "../../../../../../../../../shared/utils/dateFormat";

export const setDefaults = createAsyncThunk(
    "salesInvoiceForm/setDefaults",
    async (accountId, { dispatch, getState, rejectWithValue }) => {
        try {
            const myCompany = await myCompaniesApi.endpoints.getLatestMyCompany
                .initiate({ accountId })(dispatch, getState)
                .unwrap();
            const defaultCountry = await countriesApi.endpoints.getCountry
                .initiate({
                    accountId,
                    countryId: myCompany?.country?.countryId || defaults.invoices.country,
                })(dispatch, getState)
                .unwrap();
            const defaultDepartment = await departmentsApi.endpoints.getDefaultDepartment
                .initiate({
                    accountId,
                })(dispatch, getState)
                .unwrap();

            const { countryId, currencyId } = defaultCountry;

            return {
                buyer: null,
                publicId: "",
                taxPaymentDate: dateFormat.todayInInputFormat(),
                dueDate: dateFormat.todayInInputFormat(),
                issueDate: dateFormat.todayInInputFormat(),
                consumptionCountryId: countryId,
                type: defaults.invoices.type,
                externalId: "",
                text: "",
                currencyId,
                paymentMethod: defaults.invoices.paymentMethod,
                bankAccount: null,
                contract: null,
                department: defaultDepartment,
                variableSymbol: "",
                constantSymbol: "",
                specificSymbol: "",
                exchangeRate: "",
                items: [
                    {
                        product: null,
                        quantity: 1,
                        vatRate: 0,
                        totalPrice: 0,
                        vatInclusive: false,
                        contract: null,
                        department: null,
                    },
                ],
            };
        } catch (e) {
            return rejectWithValue(e);
        }
    },
);

const slice = createFormSlice("salesInvoiceForm", (builder) => {
    builder
        .addCase(setDefaults.fulfilled, (draftState, action) => {
            draftState.data = action.payload;
            draftState.status = "initialized";
        })
        .addCase(setDefaults.pending, (draftState) => {
            draftState.data = {};
            draftState.status = "initializing";
        })
        .addCase(setDefaults.rejected, (draftState) => {
            draftState.data = {};
            draftState.status = "error";
        });
});

export const { name, actions, reducer } = slice;
