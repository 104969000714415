import api from "../utils/api";
import { API_ROOT } from "../utils/constants";

const RESOURCE = "ares_lookups";
const ENDPOINT = `${API_ROOT}${RESOURCE}`;

const Ares = {
    search: (companyNumber) => api.get({ url: `${ENDPOINT}/${companyNumber}` }),
};

export default Ares;
