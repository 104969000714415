import { fork, put, take, cancel, cancelled } from "redux-saga/effects";
import * as actions from "../actions/channels";
import { getAuthToken } from "../../../utils/auth";

function* listenForChannelActions(channel) {
    let consumerEventChannel;
    const { channelName } = channel;

    try {
        consumerEventChannel = channel.eventChannel;

        while (true) {
            const action = yield take(consumerEventChannel);

            yield put(action);
        }
    } finally {
        if (yield cancelled()) {
            yield put(actions.unsubscribe(channelName));

            consumerEventChannel.close();
            channel.connection.close();

            yield put(actions.unsubscribeSuccess(channelName));
        } else {
            yield put(actions.connectionError(channelName, "WebSocket disconnected!"));
        }
    }
}

function disconnectingChannelPredicate(action, channelName) {
    return action.type === actions.CHANNEL_DISCONNECT && action.Channel.channelName === channelName;
}

function* handleChannel(Channel) {
    let channel;

    try {
        channel = new Channel(getAuthToken());

        yield put(actions.connectSuccess());

        const consumerTask = yield fork(listenForChannelActions, channel);

        yield take((action) => disconnectingChannelPredicate(action, channel.channelName));

        yield cancel(consumerTask);
        yield put(actions.disconnectSuccess());
    } catch (error) {
        yield put(
            actions.connectionError(
                channel.channelName,
                "Error while connecting to the WebSocket!",
            ),
        );
    }
}

function* watchChannels() {
    while (true) {
        const { Channel } = yield take(actions.CHANNEL_CONNECT);

        yield fork(handleChannel, Channel);
    }
}

export default watchChannels;
