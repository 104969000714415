import { Redirect } from "react-router-dom";
import { connect } from "react-redux";
import React from "react";
import PropTypes from "prop-types";
import { Spinner } from "reactstrap";

import { isAuthenticated, authenticatedUser } from "../../../../shared/redux/selectors/auth";

const LoginRedirect = <Redirect to="/login" />;
const AssistantRedirect = <Redirect to="/data-entering" />;
const ManagerRedirect = <Redirect to="/manager/skipped-uploaded-documents" />;
const SupervisorRedirect = <Redirect to="/supervisor/account-queue" />;

const routeForRole = (role) => {
    switch (role) {
        case "assistant":
            return AssistantRedirect;
        case "manager":
            return ManagerRedirect;
        case "supervisor":
        case "admin":
            return SupervisorRedirect;
        default:
            return LoginRedirect;
    }
};

const IndexRedirect = ({ authenticated, currentUser }) => {
    if (!authenticated) return LoginRedirect;

    if (!currentUser) return <Spinner color="primary" />;

    const { role } = currentUser;

    return routeForRole(role);
};

const mapStateToProps = (state) => ({
    currentUser: authenticatedUser(state),
    authenticated: isAuthenticated(state),
});

IndexRedirect.defaultProps = {
    currentUser: null,
};

IndexRedirect.propTypes = {
    currentUser: PropTypes.shape({
        role: PropTypes.string,
    }),
    authenticated: PropTypes.bool.isRequired,
};

export default connect(mapStateToProps)(IndexRedirect);
