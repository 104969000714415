import api from "../utils/api";
import { API_ROOT } from "../utils/constants";
import { Schemas } from "../shared/redux/schemas";
import { deprecationWarning } from "../utils/deprecation";

const Documents = {
    update: (accountId, documentId, data) => {
        deprecationWarning(
            "DocumentsService.update is deprecated. Use redux connected useUpdateDocumentMutation from uploadedDocumentsApi instead.",
        );

        return api.patch({
            url: `${API_ROOT}accounting/${accountId}/uploaded_documents/${documentId}`,
            data,
            schema: Schemas.UPLOADED_DOCUMENT,
        });
    },
};

export default Documents;
