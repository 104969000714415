export const SHOW_MODAL = "SHOW_MODAL";

export const showModal = ({ modalProps, modalType }) => ({
    type: SHOW_MODAL,
    modalProps,
    modalType,
});

export const HIDE_MODAL = "HIDE_MODAL";
export const hideModal = () => ({
    type: HIDE_MODAL,
});
