import {
    ActivityStatuses,
    SKIP,
    PROCESS_SUCCESS,
    END_ACTIVITY,
    END_ACTIVITY_FAILURE,
    END_ACTIVITY_SUCCESS,
    NEW_ACTIVITY,
    UPDATE_ACTIVITY,
    REMOVE_FORM,
    SWITCH_FORM,
} from "../../actions/dataEntering";
import { LOGOUT_SUCCESS } from "../../actions/auth";

const initialState = {
    status: ActivityStatuses.INITIAL,
    startedAt: null,
    loading: false,
    error: null,
    selectedFormId: null,
    entityId: null,
    entityType: null,
};

export default (state = initialState, { type, payload }) => {
    switch (type) {
        case NEW_ACTIVITY: {
            return {
                ...state,
                startedAt: payload.startedAt,
                status: payload.status,
            };
        }
        case SWITCH_FORM: {
            return {
                ...state,
                selectedFormId: payload,
            };
        }
        case REMOVE_FORM: {
            return {
                ...state,
                selectedFormId: null,
            };
        }
        case UPDATE_ACTIVITY: {
            return {
                ...state,
                ...payload,
            };
        }
        case END_ACTIVITY: {
            return {
                ...state,
                status: ActivityStatuses.ENDING,
                loading: true,
            };
        }
        case SKIP.SUCCESS: {
            return {
                ...state,
                status: ActivityStatuses.SKIPPED,
            };
        }
        case PROCESS_SUCCESS: {
            return {
                ...state,
                status: ActivityStatuses.PROCESSED,
            };
        }
        case LOGOUT_SUCCESS:
        case END_ACTIVITY_SUCCESS: {
            return {
                ...initialState,
            };
        }
        case END_ACTIVITY_FAILURE: {
            return {
                ...state,
                loading: false,
                error: payload.errors,
            };
        }
        default:
            return state;
    }
};
