import PaginateUtils from "../../../utils/paginateUtils";
import { fetchTypes, createActions, createAction } from "../utils/actionCreators";
import SkippedUploadedDocument from "../../../services/skippedUploadedDocument";
/**
 *************************** Fetch actions ***********************************
 */
export const SKIPPED_UPLOADED_DOCUMENTS = fetchTypes("SKIPPED_UPLOADED_DOCUMENTS");
export const SKIPPED_UPLOADED_DOCUMENT = fetchTypes("SKIPPED_UPLOADED_DOCUMENT");

export const fetchSkippedUploadedDocuments = (filters) => ({
    type: "@@fetch/REQUEST",
    resource: "skippedUploadedDocuments",
    types: SKIPPED_UPLOADED_DOCUMENTS,
    key: PaginateUtils.objectToKey(filters),
    fn: (params) => SkippedUploadedDocument.filter(params, filters),
});

export const skippedUploadedDocument = createActions(SKIPPED_UPLOADED_DOCUMENT);

/**
 *************************** Flow actions ***********************************
 */
export const LOAD_SKIPPED_UPLOADED_DOCUMENT = "@@arnold/skippedUploadedDocument/LOAD";
export const loadSkippedUploadedDocument = (payload) =>
    createAction(LOAD_SKIPPED_UPLOADED_DOCUMENT, { payload });

export const ENQUEUE = "@@arnold/management/ENQUEUE_SKIPPING";
export const ENQUEUE_SUCCESS = "@@arnold/management/ENQUEUE_SKIPPING_SUCCESS";
export const ENQUEUE_FAILURE = "@@arnold/management/ENQUEUE_SKIPPING_FAILURE";

export const enqueueSkippingSuccess = (payload, listenerId) => ({
    type: ENQUEUE_SUCCESS,
    payload,
    meta: {
        listenerId,
    },
});

export const enqueueSkippingFailure = (payload, listenerId) => ({
    type: ENQUEUE_FAILURE,
    payload,
    error: true,
    meta: {
        listenerId,
    },
});

export const PROCESS = "@@arnold/management/PROCESS_SKIPPING";
export const PROCESS_SUCCESS = "@@arnold/management/PROCESS_SKIPPING_SUCCESS";
export const PROCESS_FAILURE = "@@arnold/management/PROCESS_SKIPPING_FAILURE";

export const processSkippingSuccess = (payload, listenerId) => ({
    type: PROCESS_SUCCESS,
    payload,
    meta: {
        listenerId,
    },
});

export const processSkippingFailure = (payload, listenerId) => ({
    type: PROCESS_FAILURE,
    payload,
    error: true,
    meta: {
        listenerId,
    },
});
