import React from "react";
import { DropdownItem } from "reactstrap";
import { LOCALES } from "../../constants/locales";
import useTranslation from "../../hooks/useTranslation";

const LocaleSwitch = (props) => {
    const { locale, setLocale, t } = useTranslation();
    const other = LOCALES.filter((l) => l !== locale)[0];
    const label = t(`locales.native.${other}`);

    return (
        <DropdownItem {...props} onClick={() => setLocale(other)}>
            {label}
        </DropdownItem>
    );
};

export default LocaleSwitch;
