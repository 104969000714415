import React from "react";
import PropTypes from "prop-types";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import htmlUtils from "../../../../../../../utils/htmlUtils";
import "./index.scss";

const AssignedAccountDetailModal = ({ hideModal, account }) => (
    <Modal
        isOpen
        className="assigned-account-detail-modal"
        fade={false}
        centered
        toggle={hideModal}
    >
        <ModalHeader toggle={hideModal}>{account.name}</ModalHeader>
        <ModalBody>
            <div dangerouslySetInnerHTML={{ __html: htmlUtils.sanitize(account.wiki) }} />
        </ModalBody>
    </Modal>
);

AssignedAccountDetailModal.propTypes = {
    account: PropTypes.shape({
        id: PropTypes.number,
        name: PropTypes.string,
        expertUrl: PropTypes.string,
        code: PropTypes.string,
        contractState: PropTypes.string,
        ico: PropTypes.string,
        vatin: PropTypes.string,
        wiki: PropTypes.string,
    }).isRequired,
    hideModal: PropTypes.func.isRequired,
};

export default AssignedAccountDetailModal;
