import { v4 as uuidv4 } from "uuid";

export const ENQUEUE_NOTIFICATION = "@notify/ENQUEUE_NOTIFICATION";
export const CLEAR_NOTIFICATION = "@notify/CLEAR";
export const CLEAR_ALL_NOTIFICATIONS = "@notify/CLEAR_ALL";

export const notify = (payload) => {
    const nextPayload = payload;

    if (!nextPayload.id) nextPayload.id = uuidv4();

    return {
        type: ENQUEUE_NOTIFICATION,
        payload: nextPayload,
    };
};

export const clearNotification = (payload) => ({
    type: CLEAR_NOTIFICATION,
    payload,
});

export const clearAllNotifications = () => ({
    type: CLEAR_ALL_NOTIFICATIONS,
});
