import { normalize } from "normalizr";
import { camelizeKeys, decamelizeKeys } from "humps";
import { isEmpty } from "lodash";
import { getAuthToken } from "./auth";

const filterParams = (params) =>
    Object.keys(params).reduce((accumulator, key) => {
        accumulator[`filter[${key}]`] = params[key]; // eslint-disable-line no-param-reassign
        return accumulator;
    }, {});

const getNextPageUrl = (response) => {
    if (!response.meta) {
        return null;
    }

    const link = response.meta?.pagination?.next;
    if (!link) {
        return null;
    }

    const nextLink = link.trim().split("/").slice(-3).join("/");

    if (!nextLink) {
        return null;
    }

    return `/${nextLink}`;
};

const getQueryString = (params) =>
    Object.entries(params)
        .map((pair) => pair.map(encodeURIComponent).join("="))
        .join("&");

const handleResponse = (response, schema) => {
    if (response.status === 204 || response.status === 205) {
        return null;
    }

    return response.json().then((json) => {
        if (!response.ok) {
            const error = (json && json.errors) || response.statusText;

            return Promise.reject(error);
        }

        let payload = camelizeKeys(json);
        const nextPageUrl = getNextPageUrl(payload);

        if (isEmpty(payload)) return nextPageUrl ? { ...payload, nextPageUrl } : payload;

        if (schema) payload = normalize(payload, schema);

        return nextPageUrl ? { ...payload, nextPageUrl } : payload;
    });
};

const request = async (params) => {
    const method = params.method || "GET";
    let queryString = "";
    let body;
    const headers = params.headers || {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${getAuthToken()}`,
    };

    const data = { ...params.data, ...filterParams(params.filters || {}) };

    if (Object.keys(data).length) {
        if (["GET", "DELETE"].indexOf(method) > -1) {
            queryString = `?${getQueryString(decamelizeKeys(data))}`;
        } else {
            const decamelized = decamelizeKeys(data);
            body = JSON.stringify(decamelized);
        }
    }

    const url = params.url + queryString;

    const response = await fetch(url, { method, headers, body });

    return handleResponse(response, params.schema);
};

export default {
    get: (params) => request({ method: "GET", credentials: "same-origin", ...params }),
    post: (params) => request({ method: "POST", credentials: "same-origin", ...params }),
    put: (params) => request({ method: "PUT", credentials: "same-origin", ...params }),
    patch: (params) => request({ method: "PATCH", credentials: "same-origin", ...params }),
    delete: (params) => request({ method: "DELETE", credentials: "same-origin", ...params }),
};
