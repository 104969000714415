import React, { Suspense } from "react";
import { compose } from "redux";
import { Route, Switch, Redirect } from "react-router-dom";
import { Supervisor as SupervisorRedirect } from "../auth/hocs/withAuthRedirect";
import SupervisorAppbar from "./components/AppbarContainer";
import NotFound from "../../shared/components/NotFound";
import Loading from "../../shared/components/Loading";
import withSubscribedChannel from "../../shared/hocs/withSubscribedChannel";
import AccountUpdatesChannel from "../../shared/channels/AccountUpdatesChannel";

const AccountQueueScreen = React.lazy(() => import("./routes/AccountQueueScreen"));

const ProcessingStatsScreen = React.lazy(() => import("./routes/ProcessingStatsScreen"));

const ProcessingPerformanceScreen = React.lazy(() =>
    import("./routes/ProcessingPerformanceScreen"),
);

const Supervisor = () => (
    <Suspense fallback={<Loading />}>
        <SupervisorAppbar />
        <div className="fill-container">
            <Switch>
                <Redirect exact from="/supervisor" to="/supervisor/account-queue" />
                <Route path="/supervisor/account-queue" component={AccountQueueScreen} />
                <Route path="/supervisor/processing-stats" component={ProcessingStatsScreen} />
                <Route
                    path="/supervisor/processing-performance"
                    component={ProcessingPerformanceScreen}
                />
                <Route component={NotFound} />
            </Switch>
        </div>
    </Suspense>
);

export default compose(
    SupervisorRedirect,
    withSubscribedChannel(AccountUpdatesChannel),
)(Supervisor);
