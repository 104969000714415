import { fork, put, take, race, cancel, delay } from "redux-saga/effects";
import { logout } from "../actions/auth";
import { START_WATCHING_INACTIVITY, STOP_WATCHING_INACTIVITY } from "../actions/inactivity";
import { TIMEOUT_IN_MILLISECONDS, INACTIVITY_ACTION_PATTERN } from "../../constants/inactivity";

export function* startWatchingUserInactivity() {
    while (true) {
        const { timedOut } = yield race({
            _: take((action) => INACTIVITY_ACTION_PATTERN.test(action.type)),
            timedOut: delay(TIMEOUT_IN_MILLISECONDS),
        });

        if (timedOut) {
            yield put(logout());
        }
    }
}

export function* watchUserInactivity() {
    while (true) {
        yield take(START_WATCHING_INACTIVITY);

        const inactivityWorker = yield fork(startWatchingUserInactivity);

        yield take(STOP_WATCHING_INACTIVITY);

        yield cancel(inactivityWorker);
    }
}

const inactivitySagas = [fork(watchUserInactivity)];

export default inactivitySagas;
