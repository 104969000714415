import React, { useState } from "react";
import PropTypes from "prop-types";
import Polyglot from "node-polyglot";
import { I18nContext } from "./I18nContext";

export const I18nProvider = ({ defaultLocale, messages, children }) => {
    const [locale, setLocale] = useState(defaultLocale);

    const polyglot = new Polyglot({
        locale,
        phrases: messages[locale],
    });

    const translate = polyglot.t.bind(polyglot);
    const has = polyglot.has.bind(polyglot);

    const translateWithFallback = (translationKey, fallback) => {
        if (has(translationKey)) {
            return translate(translationKey);
        }

        return fallback;
    };

    return (
        <I18nContext.Provider
            value={{
                t: translate,
                translateWithFallback,
                has,
                locale,
                setLocale,
            }}
        >
            {children}
        </I18nContext.Provider>
    );
};

I18nProvider.propTypes = {
    defaultLocale: PropTypes.string.isRequired,
    messages: PropTypes.shape({}).isRequired,
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
};

export default I18nProvider;
