import React from "react";
import PropTypes from "prop-types";
import "./index.scss";

const FormToolbar = ({ children }) => {
    return <div className="form-toolbar">{children}</div>;
};

FormToolbar.propTypes = {
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
};

export default FormToolbar;
