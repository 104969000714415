import React from "react";
import { useDispatch } from "react-redux";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import PropTypes from "prop-types";
import { SKIP } from "../../../../../../shared/redux/actions/dataEntering";
import { hideModal } from "../../../../../../shared/redux/actions/modal";
import Form from "../../../../../../shared/components/AnnotationForm";
import useDataEntering from "../../../../../../shared/hooks/dataEntering/useDataEntering";
import useAsyncAction from "../../../../../../shared/hooks/useAsyncAction";

const SkipModal = ({ title }) => {
    const dispatch = useDispatch();
    const dataEntering = useDataEntering();
    const skip = useAsyncAction(SKIP);

    const hide = () => dispatch(hideModal());

    const onSubmit = async (data) => skip({ data, dataEntering });

    return (
        <Modal isOpen fade={false} toggle={hide}>
            <ModalHeader>{title}</ModalHeader>
            <ModalBody>
                <Form onSubmit={onSubmit} cancel={hide} onSubmitSuccess={hide} />
            </ModalBody>
        </Modal>
    );
};

SkipModal.propTypes = {
    title: PropTypes.string.isRequired,
};

export default SkipModal;
