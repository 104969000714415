import React from "react";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import { hideModal } from "../../../../../../shared/redux/actions/modal";
import Form from "../../../../../../shared/components/AnnotationForm";

const ResolveSkippingModal = ({ resolveFn, title, ...rest }) => {
    const dispatch = useDispatch();

    const hide = () => dispatch(hideModal());

    return (
        <Modal isOpen fade={false} toggle={hide}>
            <ModalHeader>{title}</ModalHeader>
            <ModalBody>
                <Form onSubmit={resolveFn} cancel={hide} onSubmitSuccess={hide} {...rest} />
            </ModalBody>
        </Modal>
    );
};

ResolveSkippingModal.propTypes = {
    title: PropTypes.string.isRequired,
    resolveFn: PropTypes.func.isRequired,
};

export default ResolveSkippingModal;
