import React from "react";
import { Button } from "reactstrap";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEthernet } from "@fortawesome/free-solid-svg-icons";
import InfoScreen from "../InfoScreen";
import translate from "../../hocs/withTranslation";

const FailedSubscriptionScreen = ({ t }) => (
    <InfoScreen>
        <FontAwesomeIcon icon={faEthernet} size="8x" />
        <h2>{t("channels.connection.failed")}</h2>
        <Button onClick={() => window.location.reload(false)}>{t("actions.reload")}</Button>
    </InfoScreen>
);

FailedSubscriptionScreen.propTypes = {
    t: PropTypes.func.isRequired,
};

export default translate(FailedSubscriptionScreen);
