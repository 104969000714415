import { eventChannel, END } from "redux-saga";
import connection from "./connection";
import * as ChannelActions from "../redux/actions/channels";

export function publish(socket, payload) {
    if (!socket) {
        throw new Error("Socket not given!");
    }

    const msg = JSON.stringify(payload);
    socket.send(msg);
}

export function channelIdentifier(channel) {
    return JSON.stringify({ channel });
}

export function subscribe(socket, channelName) {
    return publish(socket, {
        command: "subscribe",
        identifier: channelIdentifier(channelName),
    });
}

export function unsubscribe(socket, channelName) {
    return publish(socket, {
        command: "unsubscribe",
        identifier: channelIdentifier(channelName),
    });
}

export function createEventChannel(socket, channelName, actions) {
    if (!socket) {
        throw new Error("WebSocket connection was not established!");
    }

    return eventChannel((emit) => {
        socket.onopen = () => {
            subscribe(socket, channelName);
            emit(ChannelActions.subscribe(channelName));

            if (actions.onSubscribe) {
                actions.onSubscribe(emit);
            }
        };

        socket.onmessage = (event) => {
            const { data } = event;
            const parsedData = JSON.parse(data);

            if (parsedData.type === "confirm_subscription") {
                emit(ChannelActions.subscribeSuccess(channelName));
            }

            if (actions.onMessage) {
                actions.onMessage(event, emit);
            }
        };

        socket.onerror = (event) => {
            if (actions.onError) {
                actions.onError(event, emit);
            }
        };

        socket.onclose = () => {
            emit(END);
        };

        return () => {
            if (socket.readyState === WebSocket.OPEN) {
                unsubscribe(socket, channelName);
            }
        };
    });
}

const defaultActions = {
    onSubscribe: null,
    onUnsubscribe: null,
    onMessage: null,
    onError: (event, emit) => emit({ type: "WS_ERRRRR" }),
};

export default function AuthenticatedChannel(
    authenticationToken,
    channelName,
    actions = defaultActions,
) {
    if (!authenticationToken) {
        throw new Error("Authentication token not given!");
    }

    if (!channelName) {
        throw new Error("Channel name was not specified!");
    }

    this.channelName = channelName;
    this.connection = connection(authenticationToken);
    this.eventChannel = createEventChannel(this.connection, this.channelName, actions);
}
