import React from "react";
import PropTypes from "prop-types";
import "./index.scss";

const CenteredContainer = ({ children }) => <div className="centered-container">{children}</div>;

CenteredContainer.propTypes = {
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
};

export default CenteredContainer;
