import { call, put, takeLatest, delay } from "redux-saga/effects";
import { isEmpty } from "lodash";
import Ares from "../../../../services/ares";
import { notify } from "../../actions/notifications";
import { ARES, searchAresFailure, searchAresSuccess } from "../../actions/ares/search";

export function* searchAres(action) {
    const { companyNumber } = action.payload;

    yield delay(500);
    try {
        const payload = yield call(Ares.search, companyNumber);
        if (isEmpty(payload))
            yield put(notify({ translatedText: "messages.ares.not_found", autoClear: true }));
        yield put(searchAresSuccess(payload));
    } catch (error) {
        yield put(searchAresFailure(error));
        yield put(notify({ text: error[0] && error[0].detail }));
    }
}

export default [takeLatest(ARES.REQUEST, searchAres)];
