import { createSlice } from "@reduxjs/toolkit";
import { NEW_ACTIVITY } from "../../../../../../../../shared/redux/actions/dataEntering";

export const initialFormState = {
    errors: [],
    currentStep: 1,
    error: null,
    status: "uninitialized",
    data: {},
};

function isNewActivity(action) {
    return action.type === NEW_ACTIVITY;
}

export function createFormSlice(name, extraReducers = () => {}, initialState = initialFormState) {
    return createSlice({
        name,
        initialState,
        reducers: {
            update(draftState, action) {
                draftState.data = { ...draftState.data, ...action.payload };
            },
            nextStep(draftState) {
                draftState.currentStep += 1;
            },
            prevStep(draftState) {
                draftState.currentStep -= 1;
            },
            setStep(draftState, action) {
                draftState.currentStep = action.payload;
            },
            updateErrors(draftState, action) {
                draftState.errors = action.payload;
            },
            clearErrors(draftState) {
                draftState.errors = [];
            },
            reset() {
                return initialState;
            },
        },
        extraReducers: (builder) => {
            extraReducers(builder);

            // reset form on new activity
            builder.addMatcher(isNewActivity, () => initialState);
            builder.addDefaultCase(() => {});
        },
    });
}
