import { call, put, takeLatest } from "redux-saga/effects";
import { FETCH_ACCOUNT, fetchAccount as fetchAccountActions } from "../actions/accounts";
import Errors from "../../../utils/errors";
import AccountService from "../../../services/account";

function* fetchAccount(action) {
    const {
        payload: { accountId },
    } = action;

    try {
        const result = yield call(AccountService.get, accountId);

        yield put(fetchAccountActions.success(result));
    } catch (e) {
        const errors = new Errors(e);

        yield put(fetchAccountActions.failure(errors));
    }
}

const sagas = [takeLatest(FETCH_ACCOUNT.REQUEST, fetchAccount)];

export default sagas;
