import { useMemo, useEffect } from "react";
import { promiseListener } from "../redux/configureStore";

function useAsyncAction(types) {
    const asyncFn = useMemo(() => {
        const { promise, unsubscribe } = promiseListener.promiseOnAction({
            request: types.REQUEST,
            success: types.SUCCESS,
            failure: types.FAILURE,
        });
        return { promise, unsubscribe };
    }, [types]);

    useEffect(() => {
        return () => {
            asyncFn.unsubscribe();
        };
    }, [asyncFn]);

    return asyncFn.promise;
}

export default useAsyncAction;
