const toDateInputFormat = (d) => {
    if (d instanceof Date) {
        return d.toISOString().split("T")[0];
    }

    return null;
};

const todayInInputFormat = () => {
    return toDateInputFormat(new Date());
};

export default { toDateInputFormat, todayInInputFormat };
