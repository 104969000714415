import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { logout } from "../../../../shared/redux/actions/auth";
import ManagerAppbar from "../Appbar";
import { authenticatedUser } from "../../../../shared/redux/selectors/auth";

const ManagerAppbarContainer = ({ currentUser, handleLogout }) => (
    <ManagerAppbar userName={currentUser.username} handleLogout={handleLogout} />
);

ManagerAppbarContainer.propTypes = {
    currentUser: PropTypes.shape({
        username: PropTypes.string,
    }).isRequired,
    handleLogout: PropTypes.func.isRequired,
};

export const mapDispatchToProps = {
    handleLogout: logout,
};

export const mapStateToProps = (state) => ({
    currentUser: authenticatedUser(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(ManagerAppbarContainer);
