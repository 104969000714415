import api from "../utils/api";
import { API_ROOT } from "../utils/constants";
import { Schemas } from "../shared/redux/schemas";

const RESOURCE = "annotations";
const ENDPOINT = `${API_ROOT}${RESOURCE}`;

const Annotation = {
    filter: (params) =>
        api.get({
            url: ENDPOINT,
            data: params,
            schema: Schemas.ANNOTATION_ARRAY,
        }),
    create: (params) =>
        api.post({
            url: ENDPOINT,
            data: params,
            schema: Schemas.ANNOTATION,
        }),
};

export default Annotation;
