import api from "../utils/api";
import { API_ROOT } from "../utils/constants";

const BASE_ENDPOINT = `${API_ROOT}data_entering/activities`;

const Activity = {
    create: (data) => api.post({ url: BASE_ENDPOINT, data }),
};

export default Activity;
