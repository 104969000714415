import React from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import PropTypes from "prop-types";
import { Badge } from "reactstrap";
import Toast from "../Toast";
import { clearNotification } from "../../redux/actions/notifications";
import { getVisibleNotifications, getAllNotifications } from "../../redux/selectors/notifications";
import withTranslation from "../../hocs/withTranslation";
import "./index.scss";

const NotificationsContainer = ({
    notifications,
    clearNotificationConnect,
    remainingNotifications,
    t,
}) =>
    remainingNotifications > 0 && (
        <div className="notifications--container">
            <Badge>{`${t("messages.notification.remaining")}: ${remainingNotifications}`}</Badge>
            {notifications.map((notification) => (
                <Toast
                    key={notification.id}
                    notification={notification}
                    dismiss={clearNotificationConnect}
                />
            ))}
        </div>
    );

export const mapStateToProps = (state) => ({
    notifications: getVisibleNotifications(state),
    remainingNotifications: getAllNotifications(state).length,
});

export const mapDispatchToProps = {
    clearNotificationConnect: clearNotification,
};

NotificationsContainer.propTypes = {
    notifications: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    clearNotificationConnect: PropTypes.func.isRequired,
    remainingNotifications: PropTypes.number.isRequired,
    t: PropTypes.func.isRequired,
};

export default compose(
    withTranslation,
    connect(mapStateToProps, mapDispatchToProps),
)(NotificationsContainer);
