import React from "react";
import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";
import UserMenuAppbar from "../../../../shared/components/Appbar/UserMenu";
import { Container, Left, Right } from "../../../../shared/components/Appbar";
import withTranslation from "../../../../shared/hocs/withTranslation";
import { Supervisor } from "../../../auth/hocs/withAuth";

const ScreenLink = ({ to, children }) => (
    <NavLink
        className="appbar-item appbar-item--highlight"
        activeClassName="appbar-item--active"
        to={to}
    >
        {children}
    </NavLink>
);

ScreenLink.propTypes = {
    to: PropTypes.string.isRequired,
    children: PropTypes.node.isRequired,
};

const SupervisorLink = Supervisor(ScreenLink);

const ManagerAppbar = ({ userName, handleLogout, t }) => (
    <Container>
        <Left>
            <ScreenLink to="/manager/skipped-uploaded-documents">
                {t("nav.manager.skipped_documents")}
            </ScreenLink>
        </Left>
        <Right>
            <SupervisorLink to="/supervisor">{t("attributes.user.role.supervisor")}</SupervisorLink>
            <UserMenuAppbar userName={userName} handleLogout={handleLogout} />
        </Right>
    </Container>
);

ManagerAppbar.defaultProps = {
    userName: "",
};

ManagerAppbar.propTypes = {
    handleLogout: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
    userName: PropTypes.string,
};

export default withTranslation(ManagerAppbar);
