import {
    DATA_ENTERING_EMPTY,
    START_SESSION,
    START_SESSION_FAILURE,
    START_SESSION_SUCCESS,
    STOP_SESSION,
    STOP_SESSION_FAILURE,
    STOP_SESSION_SUCCESS,
} from "../../actions/dataEntering";
import { LOGOUT_SUCCESS } from "../../actions/auth";

const initialState = {
    documentId: null,
    accountId: null,
    subscribed: false,
    loading: false,
    empty: false,
    error: null,
};

export default (state = initialState, { type, payload }) => {
    switch (type) {
        case START_SESSION: {
            return {
                ...initialState,
                loading: true,
            };
        }
        case DATA_ENTERING_EMPTY: {
            return {
                ...initialState,
                empty: true,
            };
        }
        case START_SESSION_SUCCESS: {
            const { accountId, documentId } = payload;

            return {
                ...initialState,
                accountId,
                documentId,
                subscribed: true,
                loading: false,
            };
        }
        case START_SESSION_FAILURE: {
            return {
                ...initialState,
                error: JSON.stringify(payload.errors),
            };
        }
        case STOP_SESSION: {
            return {
                ...state,
                loading: true,
            };
        }
        case LOGOUT_SUCCESS:
        case STOP_SESSION_SUCCESS: {
            return {
                ...initialState,
            };
        }
        case STOP_SESSION_FAILURE: {
            return {
                ...state,
                error: JSON.stringify(payload.errors),
            };
        }
        default:
            return state;
    }
};
