import api from "../utils/api";
import { API_ROOT } from "../utils/constants";
import { Schemas } from "../shared/redux/schemas";

const RESOURCE = "queued_accounts";
const ENDPOINT = `${API_ROOT}${RESOURCE}`;

const QueuedAccount = {
    get: (params) => api.get({ url: ENDPOINT, data: params, schema: Schemas.ACCOUNT_ARRAY }),
};

export default QueuedAccount;
