import api from "../utils/api";
import { API_ROOT } from "../utils/constants";

const BASE_ENDPOINT = `${API_ROOT}data_entering`;

const DataEntering = {
    start: () => api.post({ url: `${BASE_ENDPOINT}/start` }),
    stop: () => api.patch({ url: `${BASE_ENDPOINT}/stop`, data: { finishedAt: Date.now() } }),
};

export default DataEntering;
