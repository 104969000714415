import { fetchTypes } from "../utils/actionCreators";
import Statistics from "../../../services/statistics";

export const QUEUED_DOCUMENTS_OVERVIEW = fetchTypes("QUEUED_DOCUMENTS_OVERVIEW");
export const QUEUED_DOCUMENTS_DISTRIBUTION = fetchTypes("QUEUED_DOCUMENTS_DISTRIBUTION");
export const SKIPPED_DOCUMENTS_OVERVIEW = fetchTypes("SKIPPED_DOCUMENTS_OVERVIEW");
export const SKIPPED_DOCUMENTS_DISTRIBUTION = fetchTypes("SKIPPED_DOCUMENTS_DISTRIBUTION");
export const CURRENT_PERFORMANCE = fetchTypes("CURRENT_PERFORMANCE");
export const YEARS_PERFORMANCE = fetchTypes("YEARS_PERFORMANCE");
export const WEEKS_PERFORMANCE = fetchTypes("WEEKS_PERFORMANCE");

export const fetchQueuedDocumentsOverview = () => ({
    type: "@@fetch/REQUEST",
    resource: "statistics",
    types: QUEUED_DOCUMENTS_OVERVIEW,
    key: "queuedDocumentsOverview",
    fn: () => Statistics.api.getQueuedDocumentsOverview(),
});

export const fetchQueuedDocumentsDistribution = () => ({
    type: "@@fetch/REQUEST",
    resource: "statistics",
    types: QUEUED_DOCUMENTS_DISTRIBUTION,
    key: "queuedDocumentsDistribution",
    fn: () => Statistics.api.getQueuedDocumentsDistribution(),
});

export const fetchSkippedDocumentsOverview = () => ({
    type: "@@fetch/REQUEST",
    resource: "statistics",
    types: SKIPPED_DOCUMENTS_OVERVIEW,
    key: "skippedDocumentsOverview",
    fn: () => Statistics.api.getSkippedDocumentsOverview(),
});

export const fetchSkippedDocumentsDistribution = () => ({
    type: "@@fetch/REQUEST",
    resource: "statistics",
    types: SKIPPED_DOCUMENTS_DISTRIBUTION,
    key: "skippedDocumentsDistribution",
    fn: () => Statistics.api.getSkippedDocumentsDistribution(),
});

export const fetchCurrentPerformance = () => ({
    type: "@@fetch/REQUEST",
    resource: "statistics",
    types: CURRENT_PERFORMANCE,
    key: "currentPerformance",
    fn: () => Statistics.api.getCurrentPerformance(),
});

export const fetchYearsPerformance = () => ({
    type: "@@fetch/REQUEST",
    resource: "statistics",
    types: YEARS_PERFORMANCE,
    key: "yearsPerformance",
    fn: () => Statistics.api.getYearsPerformance(),
});

export const fetchWeeksPerformance = () => ({
    type: "@@fetch/REQUEST",
    resource: "statistics",
    types: WEEKS_PERFORMANCE,
    key: "weeksPerformance",
    fn: () => Statistics.api.getWeeksPerformance(),
});
