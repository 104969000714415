import { combineReducers } from "redux";

import * as ChannelActions from "../../actions/channels";
import { connectionStates } from "../../../constants/channels";

const initialState = {
    state: connectionStates.INITIAL,
};

const channelReducer =
    (channelName) =>
    (state = initialState, action) => {
        if (!channelName || typeof channelName !== "string") {
            throw new Error("Expected channel name to be a string.");
        }

        if (channelName !== action.channelName) {
            return state;
        }

        switch (action.type) {
            case ChannelActions.CHANNEL_SUBSCRIBE:
                return {
                    state: connectionStates.SUBSCRIBING,
                };
            case ChannelActions.CHANNEL_SUBSCRIBE_SUCCESS:
                return { state: connectionStates.SUBSCRIBED };
            case ChannelActions.CHANNEL_UNSUBSCRIBE:
                return { state: connectionStates.UNSUBSCRIBING };
            case ChannelActions.CHANNEL_UNSUBSCRIBE_SUCCESS:
                return { state: connectionStates.UNSUBSCRIBED };
            case ChannelActions.CHANNEL_CONNECTION_ERROR:
                return { state: connectionStates.FAILED };
            default:
                return state;
        }
    };

export default combineReducers({
    DataEntryChannel: channelReducer("DataEntryChannel"),
    AccountUpdatesChannel: channelReducer("AccountUpdatesChannel"),
});
