import React from "react";
import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";
import UserMenuAppbar from "../../../../shared/components/Appbar/UserMenu";
import { Container, Left, Right } from "../../../../shared/components/Appbar";
import withTranslation from "../../../../shared/hocs/withTranslation";

const ScreenLink = ({ to, children }) => (
    <NavLink
        className="appbar-item appbar-item--highlight"
        activeClassName="appbar-item--active"
        {...{ to }}
    >
        {children}
    </NavLink>
);

ScreenLink.propTypes = {
    to: PropTypes.string.isRequired,
    children: PropTypes.node.isRequired,
};

const SupervisorAppbar = ({ userName, handleLogout, t }) => (
    <Container>
        <Left>
            <ScreenLink to="/supervisor/account-queue">{t("nav.manager.account_queue")}</ScreenLink>
            <ScreenLink to="/supervisor/processing-stats">
                {t("nav.manager.processing_stats")}
            </ScreenLink>
            <ScreenLink to="/supervisor/processing-performance">
                {t("nav.manager.processing_performance")}
            </ScreenLink>
        </Left>
        <Right>
            <ScreenLink to="/manager">{t("attributes.user.role.manager")}</ScreenLink>
            <UserMenuAppbar userName={userName} handleLogout={handleLogout} />
        </Right>
    </Container>
);

SupervisorAppbar.defaultProps = {
    userName: "",
};

SupervisorAppbar.propTypes = {
    handleLogout: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
    userName: PropTypes.string,
};

export default withTranslation(SupervisorAppbar);
