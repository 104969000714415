import { camelizeKeys } from "humps";
import { normalize } from "normalizr";
import { Schemas } from "../../redux/schemas";
import AuthenticatedChannel from "../channel";

export default function AccountUpdatesChannel(authenticationToken) {
    const CHANNEL_NAME = "AccountUpdatesChannel";

    const channelActions = {
        onMessage: (event, emit) => {
            const { data } = event;
            const parsedData = JSON.parse(data);

            if (parsedData.type !== "ping" && parsedData.message) {
                const camelized = camelizeKeys(parsedData.message);
                const normalized = normalize(camelized, Schemas.ACCOUNT);

                emit({ type: "UPDATE_ENTITIES", payload: normalized });
            }
        },
    };

    AuthenticatedChannel.call(this, authenticationToken, CHANNEL_NAME, channelActions);
}

AccountUpdatesChannel.channelName = "AccountUpdatesChannel";
