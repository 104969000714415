/*
======== Action types ========
 */
export const CHANNEL_CONNECT = "@@arnold/channel/CONNECT";
export const CHANNEL_SUBSCRIBE = "@@arnold/channel/SUBSCRIBE";
export const CHANNEL_UNSUBSCRIBE = "@@arnold/channel/UNSUBSCRIBE";
export const CHANNEL_DISCONNECT = "@@arnold/channel/DISCONNECT";
export const CHANNEL_CONNECTION_ERROR = "@@arnold/channel/CONNECTION_ERROR";
export const CHANNEL_CONNECT_SUCCESS = "@@arnold/channel/CONNECT_SUCCESS";
export const CHANNEL_SUBSCRIBE_SUCCESS = "@@arnold/channel/SUBSCRIBE_SUCCESS";
export const CHANNEL_UNSUBSCRIBE_SUCCESS = "@@arnold/channel/UNSUBSCRIBE_SUCCESS";
export const CHANNEL_DISCONNECT_SUCCESS = "@@arnold/channel/DISCONNECT_SUCCESS";

/*
======== Action creators ========
 */
export const connect = (Channel) => ({
    type: CHANNEL_CONNECT,
    Channel,
});

export const disconnect = (Channel) => ({
    type: CHANNEL_DISCONNECT,
    Channel,
});

export const connectionError = (channelName, error) => ({
    type: CHANNEL_CONNECTION_ERROR,
    channelName,
    error,
});

export const connectSuccess = () => ({
    type: CHANNEL_CONNECT_SUCCESS,
});

export const disconnectSuccess = () => ({
    type: CHANNEL_DISCONNECT_SUCCESS,
});

export const subscribe = (channelName) => ({
    type: CHANNEL_SUBSCRIBE,
    channelName,
});

export const subscribeSuccess = (channelName) => ({
    type: CHANNEL_SUBSCRIBE_SUCCESS,
    channelName,
});

export const unsubscribe = (channelName) => ({
    type: CHANNEL_UNSUBSCRIBE,
    channelName,
});

export const unsubscribeSuccess = (channelName) => ({
    type: CHANNEL_UNSUBSCRIBE_SUCCESS,
    channelName,
});
