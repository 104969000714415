import { ARES, SEARCH_ARES_CLEAR } from "../actions/ares/search";

const initialState = {
    result: {},
    isFetching: false,
};

const aresReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case ARES.REQUEST:
            return {
                result: {},
                isFetching: true,
            };
        case ARES.SUCCESS:
            return {
                result: payload,
                isFetching: false,
            };
        case ARES.FAILURE:
            return initialState;
        case SEARCH_ARES_CLEAR:
            return initialState;
        default:
            return state;
    }
};

export default aresReducer;
