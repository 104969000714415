import React from "react";
import PropTypes from "prop-types";
import CenteredContainer from "../CenteredContainer";

const InfoScreen = ({ children }) => (
    <CenteredContainer>
        <CenteredContainer>{children}</CenteredContainer>
    </CenteredContainer>
);

InfoScreen.propTypes = {
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
};

export default InfoScreen;
