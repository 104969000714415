import {
    QUEUED_DOCUMENTS_OVERVIEW,
    QUEUED_DOCUMENTS_DISTRIBUTION,
    SKIPPED_DOCUMENTS_OVERVIEW,
    SKIPPED_DOCUMENTS_DISTRIBUTION,
    CURRENT_PERFORMANCE,
    YEARS_PERFORMANCE,
    WEEKS_PERFORMANCE,
} from "../actions/statistics";

const initialState = {
    queuedDocumentsOverview: undefined,
    queuedDocumentsDistribution: undefined,
    skippedDocumentsOverview: undefined,
    skippedDocumentsDistribution: undefined,
    currentPerformance: undefined,
    yearsPerformance: undefined,
    weeksPerformance: undefined,
};

const statisticsReducer = (state = initialState, action) => {
    const types = [
        QUEUED_DOCUMENTS_OVERVIEW,
        QUEUED_DOCUMENTS_DISTRIBUTION,
        SKIPPED_DOCUMENTS_OVERVIEW,
        SKIPPED_DOCUMENTS_DISTRIBUTION,
        CURRENT_PERFORMANCE,
        YEARS_PERFORMANCE,
        WEEKS_PERFORMANCE,
    ].map((ts) => ts.SUCCESS);

    if (types.includes(action.type)) {
        return { ...state, [action.key]: action.payload };
    }

    return { ...state };
};

export default statisticsReducer;
