import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import FailedSubscriptionScreen from "../../components/FailedSubscription";
import * as ChannelActions from "../../redux/actions/channels";
import { subscriptionState } from "../../redux/selectors/channels";
import LoadingScreen from "../../components/Loading";
import { connectionStates } from "../../constants/channels";

const withSubscribedChannel = (channel) => (WrappedComponent) => {
    class ChannelSubscription extends PureComponent {
        componentDidMount() {
            const { connectChannel } = this.props;

            connectChannel(channel);
        }

        componentWillUnmount() {
            const { disconnectChannel } = this.props;

            disconnectChannel(channel);
        }

        render() {
            const { subscription, ...rest } = this.props;

            if (subscription === connectionStates.SUBSCRIBED) return <WrappedComponent {...rest} />;

            if (subscription === connectionStates.FAILED) return <FailedSubscriptionScreen />;
            return <LoadingScreen />;
        }
    }

    ChannelSubscription.propTypes = {
        subscription: PropTypes.string.isRequired,
        connectChannel: PropTypes.func.isRequired,
        disconnectChannel: PropTypes.func.isRequired,
    };

    const mapStateToProps = (state) => ({
        subscription: subscriptionState(state, channel.channelName),
    });

    const mapDispatchToProps = {
        connectChannel: ChannelActions.connect,
        disconnectChannel: ChannelActions.disconnect,
    };

    return connect(mapStateToProps, mapDispatchToProps)(ChannelSubscription);
};

export default withSubscribedChannel;
