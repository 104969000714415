import { Redirect, Route } from "react-router-dom";
import { connect } from "react-redux";
import React from "react";
import PropTypes from "prop-types";
import { isAuthenticated } from "../../../../shared/redux/selectors/auth";

const PrivateRoute = ({ component: Component, isAuthenticatedConnect, ...rest }) => (
    <Route
        {...rest}
        render={(props) =>
            isAuthenticatedConnect ? (
                <Component {...props} />
            ) : (
                <Redirect
                    to={{
                        pathname: "/login",
                    }}
                />
            )
        }
    />
);

function mapStateToProps(state) {
    return {
        isAuthenticatedConnect: isAuthenticated(state),
    };
}

PrivateRoute.propTypes = {
    component: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.func,
        PropTypes.node,
        PropTypes.elementType,
    ]).isRequired,
    isAuthenticatedConnect: PropTypes.bool.isRequired,
};

export default connect(mapStateToProps)(PrivateRoute);
