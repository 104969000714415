import React, { Suspense } from "react";
import { Route, Switch } from "react-router-dom";
import LoadingScreen from "../../shared/components/Loading";
import NotFound from "../../shared/components/NotFound";
import { Assistant } from "../auth/hocs/withAuthRedirect";

const DataEnteringRoute = React.lazy(() => import("./routes/dataEntering"));

const DataEntering = () => {
    return (
        <Suspense fallback={<LoadingScreen />}>
            <Switch>
                <Route exact path="/data-entering">
                    <DataEnteringRoute />
                </Route>
                <Route component={NotFound} />
            </Switch>
        </Suspense>
    );
};

export default Assistant(DataEntering);
