const REQUEST = "REQUEST";
const SUCCESS = "SUCCESS";
const FAILURE = "FAILURE";
const INVALIDATE = "INVALIDATE";
const ACTIONS = {
    FETCH: "FETCH",
    CREATE: "CREATE",
    UPDATE: "UPDATE",
};

export const types = (resource, action) =>
    [REQUEST, SUCCESS, FAILURE, INVALIDATE].reduce((accumulator, type) => {
        accumulator[type] = `@@arnold/${resource}/${action}_${type}`; // eslint-disable-line no-param-reassign
        return accumulator;
    }, {});

export const fetchTypes = (resource) => types(resource, ACTIONS.FETCH);
export const createTypes = (resource) => types(resource, ACTIONS.CREATE);
export const updateTypes = (resource) => types(resource, ACTIONS.UPDATE);

export const createAction = (type, payload = {}) => ({ type, ...payload });

export const createActions = (actionTypes) => ({
    request: (payload) => createAction(actionTypes.REQUEST, { payload }),
    success: (payload) => createAction(actionTypes.SUCCESS, { payload }),
    failure: (payload) => createAction(actionTypes.FAILURE, { payload }),
});

const actionCreators = {
    fetchTypes,
    createTypes,
    updateTypes,
    createAction,
    createActions,
};

export default actionCreators;
