import { takeLatest, fork, put, take, call, race } from "redux-saga/effects";
import DataEnteringService from "../../../../services/dataEntering";
import {
    START_SESSION,
    dataEnteringEmpty,
    startSessionFailure,
    startSessionSuccess,
    STOP_SESSION,
    stopSessionSuccess,
    stopSessionFailure,
    START_SESSION_SUCCESS,
    stopSession,
    DATA_ENTERING_EMPTY,
    START_SESSION_FAILURE,
    STOP_SESSION_SUCCESS,
} from "../../actions/dataEntering";
import Errors from "../../../../utils/errors";
import { LOGOUT_REQUEST, LOGOUT_SUCCESS } from "../../actions/auth";
import { activitiesSaga } from "./activity";

function* startDataEntering() {
    try {
        const result = yield call(DataEnteringService.start);

        if (!result) {
            yield put(dataEnteringEmpty());
        } else {
            yield put(startSessionSuccess(result));
        }

        return result;
    } catch (e) {
        const errors = new Errors(e);

        yield put(startSessionFailure(errors));

        throw e;
    }
}

function* stopDataEntering() {
    try {
        const result = yield call(DataEnteringService.stop);

        yield put(stopSessionSuccess(result));

        return result;
    } catch (e) {
        const errors = new Errors(e);

        yield put(stopSessionFailure(errors));

        throw e;
    }
}

function* sessionSaga() {
    while (true) {
        yield take(START_SESSION_SUCCESS);

        const { done } = yield race({
            logoutRequested: take(LOGOUT_REQUEST),
            done: call(activitiesSaga),
        });

        if (done) {
            yield put(stopSession());
        }
    }
}

export function* dataEnteringSaga() {
    while (true) {
        yield take(START_SESSION);

        yield fork(startDataEntering);

        yield take([
            DATA_ENTERING_EMPTY,
            START_SESSION_FAILURE,
            STOP_SESSION_SUCCESS,
            LOGOUT_SUCCESS,
        ]);
    }
}

const dataEnteringSagas = [
    fork(dataEnteringSaga),
    fork(sessionSaga),
    takeLatest(STOP_SESSION, stopDataEntering),
];

export default dataEnteringSagas;
