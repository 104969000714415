import api from "../utils/api";
import { API_ROOT } from "../utils/constants";
import { Schemas } from "../shared/redux/schemas";

const RESOURCE = "skippings";
const ENDPOINT = `${API_ROOT}${RESOURCE}`;

const Skipping = {
    get: (id) => api.get({ url: `${ENDPOINT}/${id}`, schema: Schemas.SKIPPED_UPLOADED_DOCUMENT }),
    filter: (params) =>
        api.get({
            url: ENDPOINT,
            data: params,
            schema: Schemas.SKIPPED_UPLOADED_DOCUMENT_ARRAY,
        }),
    create: (params) =>
        api.post({
            url: ENDPOINT,
            data: params,
            schema: Schemas.SKIPPED_UPLOADED_DOCUMENT,
        }),
    update: (id, params) =>
        api.patch({
            url: `${ENDPOINT}/${id}`,
            data: params,
            schema: Schemas.SKIPPED_UPLOADED_DOCUMENT,
        }),
};

export default Skipping;
