import api from "../utils/api";
import { API_ROOT } from "../utils/constants";

const CURRENT_USER_RESOURCE = "user";
const CURRENT_USER_ENDPOINT = `${API_ROOT}${CURRENT_USER_RESOURCE}`;

const User = {
    current: () => api.get({ url: CURRENT_USER_ENDPOINT }),
};

export default User;
