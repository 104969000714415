import { fetchTypes } from "../utils/actionCreators";
import PaginateUtils from "../../../utils/paginateUtils";
import AnnotationService from "../../../services/annotation";

export const FETCH_ANNOTATIONS = fetchTypes("FETCH_ANNOTATIONS");

export const fetchAnnotations = (params) => ({
    type: "@@fetch/REQUEST",
    resource: "annotations",
    types: FETCH_ANNOTATIONS,
    key: PaginateUtils.objectToKey(params),
    fn: () => AnnotationService.filter(params),
});
