import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { logout } from "../../../../shared/redux/actions/auth";
import SupervisorAppbar from "../Appbar";
import { authenticatedUser } from "../../../../shared/redux/selectors/auth";

const SupervisorAppbarContainer = ({ currentUser, handleLogout }) => (
    <SupervisorAppbar userName={currentUser.username} handleLogout={handleLogout} />
);

SupervisorAppbarContainer.propTypes = {
    currentUser: PropTypes.shape({
        username: PropTypes.string,
    }).isRequired,
    handleLogout: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
    currentUser: authenticatedUser(state),
});

const mapDispatchToProps = {
    handleLogout: logout,
};

export default connect(mapStateToProps, mapDispatchToProps)(SupervisorAppbarContainer);
