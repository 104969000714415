export const connectionStates = {
    INITIAL: "initial",
    UNSUBSCRIBED: "unsubscribed",
    UNSUBSCRIBING: "unsubscribing",
    SUBSCRIBING: "subscribing",
    SUBSCRIBED: "subscribed",
    FAILED: "failed",
};

export default { connectionStates };
