import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import { authenticatedUser } from "../../../../shared/redux/selectors/auth";

const withAuthRedirect = (allowedRoles) => (WrappedComponent) => {
    const wrapper = ({ currentUser, ...rest }) => {
        if (!currentUser || !currentUser.role) {
            return null;
        }

        return allowedRoles.includes(currentUser.role) ? (
            <WrappedComponent {...rest} />
        ) : (
            <Redirect to="/not_found" />
        );
    };

    const mapStateToProps = (state) => ({
        currentUser: authenticatedUser(state),
    });

    wrapper.defaultProps = {
        currentUser: null,
    };

    wrapper.propTypes = {
        currentUser: PropTypes.shape({
            role: PropTypes.string,
        }),
    };

    return connect(mapStateToProps)(wrapper);
};

export const Admin = withAuthRedirect(["admin"]);
export const Supervisor = withAuthRedirect(["admin", "supervisor"]);
export const Manager = withAuthRedirect(["admin", "supervisor", "manager"]);
export const Assistant = withAuthRedirect(["assistant"]);

export default withAuthRedirect;
