import React from "react";
import { Button, FormGroup, Label, Form, FormFeedback } from "reactstrap";
import PropTypes from "prop-types";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import translate from "../../../../shared/hocs/withTranslation";
import PasswordTextField from "../PasswordTextField";
import Input from "../../../../shared/components/FormInputs/InputWrapper";
import contract from "./contract";

const LoginForm = ({ isLoginLoading, onSubmit, t }) => {
    const {
        register,
        handleSubmit,
        setError,
        formState: { errors },
    } = useForm({ resolver: yupResolver(contract) });

    const submit = handleSubmit(async (data) => {
        try {
            await onSubmit(data);
        } catch (e) {
            e.parsedErrors().forEach((err) =>
                setError(err.source.pointer, {
                    type: err.code,
                    message: err.detail,
                }),
            );
        }
    });

    return (
        <Form onSubmit={submit}>
            <FormGroup>
                <Label>{t("attributes.user.username")}</Label>
                <Input
                    {...register("username")}
                    invalid={!!errors.username}
                    autoFocus
                    autoComplete="username"
                />
                {errors.username && (
                    <FormFeedback>{t(`errors.messages.${errors.username.type}`)}</FormFeedback>
                )}
            </FormGroup>
            <FormGroup>
                <Label>{t("attributes.user.password")}</Label>
                <PasswordTextField
                    {...register("password")}
                    invalid={!!errors.password}
                    autoComplete="current-password"
                />
                {errors.password && (
                    <FormFeedback className="d-block">
                        {t(`errors.messages.${errors.password.type}`)}
                    </FormFeedback>
                )}
            </FormGroup>
            <Button color="primary" block disabled={isLoginLoading}>
                {t("actions.login")}
            </Button>
        </Form>
    );
};

LoginForm.propTypes = {
    onSubmit: PropTypes.func.isRequired,
    isLoginLoading: PropTypes.bool.isRequired,
    t: PropTypes.func.isRequired,
};

export default translate(LoginForm);
