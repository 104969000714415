import { camelCase } from "lodash";

class Errors {
    constructor(errors) {
        this.errors = errors;
    }

    groupedErrors() {
        return this.parsedErrors().reduce((acc, error) => {
            const {
                source: { pointer },
                detail,
                code,
            } = error;

            // eslint-disable-next-line no-param-reassign
            acc[pointer] = [...(acc[pointer] || []), { detail, code }];
            return acc;
        }, {});
    }

    parsedErrors() {
        return this.errors.map((error) => {
            if (!error.source) {
                return {
                    ...error,
                    source: {
                        pointer: "general",
                    },
                };
            }

            const { pointer } = error.source;

            if (!pointer) {
                return {
                    ...error,
                    source: {
                        pointer: "general",
                    },
                };
            }

            const path = pointer.split(".");

            path[path.length - 1] = camelCase(path[path.length - 1]);

            return {
                ...error,
                source: {
                    pointer: path.join("."),
                },
            };
        });
    }

    generalErrors() {
        if (!this.errors) return null;

        return this.errors.filter((error) => error.status !== 422 || !error.source);
    }

    validationErrors() {
        if (!this.errors) return null;

        return this.errors.filter((error) => error.status === 422 && error.source);
    }
}

export default Errors;
