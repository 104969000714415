import { api as accountingApi } from "../api";
import { CollectionResponse } from "../types";
import {
    Country,
    CountryGetAllParams,
    CountryGetAllResponse,
    CountryGetParams,
    CountrySearchParams,
} from "./types";
import { ArnoldBaseQueryError } from "../../baseQuery";

export const countriesApi = accountingApi.injectEndpoints({
    endpoints: (build) => ({
        getCountries: build.query<CollectionResponse<Country>, CountrySearchParams>({
            query: ({ accountId, ...params }) => ({
                url: `api/v1/accounting/${accountId}/countries`,
                params,
            }),
        }),
        getCountry: build.query<Country, CountryGetParams>({
            query: ({ accountId, countryId }) => ({
                url: `api/v1/accounting/${accountId}/countries/${countryId}`,
            }),
        }),
        getAllCountries: build.query<CountryGetAllResponse, CountryGetAllParams>({
            async queryFn(arg, _queryApi, _extraOptions, fetchWithBQ) {
                const { accountId, records = [], page = 1 } = arg;
                const result = await fetchWithBQ({
                    url: `api/v1/accounting/${accountId}/countries`,
                    params: { page, perPage: 100 },
                });

                if (result.error) {
                    return { error: result.error as ArnoldBaseQueryError };
                }

                const { items, meta } = result.data as CollectionResponse<Country>;

                records.push(...items);

                if (page < meta.last) {
                    return this.queryFn(
                        { ...arg, page: page + 1, records },
                        _queryApi,
                        _extraOptions,
                        fetchWithBQ,
                    );
                }

                return { data: records as Country[] };
            },
        }),
    }),
});

export const { useGetCountriesQuery, useGetCountryQuery, useGetAllCountriesQuery } = countriesApi;
