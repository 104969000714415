import { createAsyncThunk } from "@reduxjs/toolkit";
import { createFormSlice } from "../../../shared/slice";
import { myCompaniesApi } from "../../../../../../../../../services/accounting/myCompany/api";
import { countriesApi } from "../../../../../../../../../services/accounting/country/api";
import { defaults } from "../../../../../../../../../utils/constants";
import { departmentsApi } from "../../../../../../../../../services/accounting/department/api";
import { pettyCashesApi } from "../../../../../../../../../services/accounting/pettyCash/api";
import dateFormat from "../../../../../../../../../shared/utils/dateFormat";

export const setDefaults = createAsyncThunk(
    "pettyCashDisburstmentForm/setDefaults",
    async (accountId, { dispatch, getState, rejectWithValue }) => {
        try {
            const myCompany = await myCompaniesApi.endpoints.getLatestMyCompany
                .initiate({ accountId })(dispatch, getState)
                .unwrap();
            const defaultCountry = await countriesApi.endpoints.getCountry
                .initiate({
                    accountId,
                    countryId: myCompany?.country?.countryId || defaults.invoices.country,
                })(dispatch, getState)
                .unwrap();
            const defaultDepartment = await departmentsApi.endpoints.getDefaultDepartment
                .initiate({
                    accountId,
                })(dispatch, getState)
                .unwrap();

            const { currencyId } = defaultCountry;

            const defaultPettyCash = await pettyCashesApi.endpoints.getDefaultPettyCash
                .initiate({ accountId, currencyId })(dispatch, getState)
                .unwrap();

            return {
                contact: null,
                pettyCashId: defaultPettyCash?.pettyCashId,
                receiptDate: dateFormat.todayInInputFormat(),
                issueDate: dateFormat.todayInInputFormat(),
                note: "",
                contract: null,
                department: defaultDepartment,
                items: [
                    {
                        rule: null,
                        description: "",
                        price: 0,
                    },
                ],
                total: 0,
            };
        } catch (e) {
            return rejectWithValue(e);
        }
    },
);

const slice = createFormSlice("pettyCashDisburstmentForm", (builder) => {
    builder
        .addCase(setDefaults.fulfilled, (draftState, action) => {
            draftState.data = action.payload;
            draftState.status = "initialized";
        })
        .addCase(setDefaults.pending, (draftState) => {
            draftState.data = {};
            draftState.status = "initializing";
        })
        .addCase(setDefaults.rejected, (draftState) => {
            draftState.data = {};
            draftState.status = "error";
        });
});

export const { name, actions, reducer } = slice;
