import { schema } from "normalizr";
import userSchema from "./user";

const annotationSchema = new schema.Entity(
    "annotations",
    {
        author: userSchema,
    },
    {
        idAttribute: (annotation) => annotation.id,
    },
);

export default annotationSchema;
