import { schema } from "normalizr";

const uploadedDocumentSchema = new schema.Entity(
    "uploadedDocuments",
    {},
    {
        idAttribute: (entity) => entity.id,
    },
);

export default uploadedDocumentSchema;
