import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { authenticatedUser } from "../../../../shared/redux/selectors/auth";

const withAuthorization = (allowedRoles) => (WrappedComponent) => {
    const wrapper = ({ currentUser, ...rest }) => {
        if (!currentUser || !currentUser.role) {
            return null;
        }

        return allowedRoles.includes(currentUser.role) ? <WrappedComponent {...rest} /> : null;
    };

    const mapStateToProps = (state) => ({
        currentUser: authenticatedUser(state),
    });

    wrapper.defaultProps = {
        currentUser: null,
    };

    wrapper.propTypes = {
        currentUser: PropTypes.shape({
            role: PropTypes.string,
        }),
    };

    return connect(mapStateToProps)(wrapper);
};

export const Admin = withAuthorization(["admin"]);
export const Supervisor = withAuthorization(["admin", "supervisor"]);
export const Manager = withAuthorization(["admin", "supervisor", "manager"]);
export const Assistant = withAuthorization(["assistant"]);

export default withAuthorization;
