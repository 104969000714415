import { schema } from "normalizr";
import accountSchema from "./account";

const skippedUploadedDocumentSchema = new schema.Entity(
    "skippedUploadedDocuments",
    {
        account: accountSchema,
    },
    {
        idAttribute: (skippedUploadedDocument) => skippedUploadedDocument.id,
    },
);

export default skippedUploadedDocumentSchema;
