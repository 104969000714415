import { SKIPPED_UPLOADED_DOCUMENTS } from "../actions/skippings";
import paginate from "./paginate";

const skippedUploadedDocumentsReducer = paginate({
    mapActionToKey: (action) => action.key,
    types: [
        SKIPPED_UPLOADED_DOCUMENTS.REQUEST,
        SKIPPED_UPLOADED_DOCUMENTS.SUCCESS,
        SKIPPED_UPLOADED_DOCUMENTS.FAILURE,
    ],
});

export default skippedUploadedDocumentsReducer;
