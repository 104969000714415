import { api as accountingApi } from "../api";
import { CollectionResponse } from "../types";
import {
    PettyCash,
    PettyCashGetAllParams,
    PettyCashGetAllResponse,
    PettyCashGetDefaultParams,
    PettyCashGetDefaultResponse,
    PettyCashSearchParams,
} from "./types";
import { ArnoldBaseQueryError } from "../../baseQuery";

export const pettyCashesApi = accountingApi.injectEndpoints({
    endpoints: (build) => ({
        getPettyCashes: build.query<CollectionResponse<PettyCash>, PettyCashSearchParams>({
            query: ({ accountId, ...params }) => ({
                url: `api/v1/accounting/${accountId}/petty_cashes`,
                params,
            }),
        }),
        getDefaultPettyCash: build.query<PettyCashGetDefaultResponse, PettyCashGetDefaultParams>({
            async queryFn(arg, _queryApi, _extraOptions, fetchWithBQ) {
                const page = arg.page || 1;
                const result = await fetchWithBQ({
                    url: `api/v1/accounting/${arg.accountId}/petty_cashes`,
                    params: { page, perPage: 100 },
                });

                if (result.error) {
                    return { error: result.error as ArnoldBaseQueryError };
                }

                const { items, meta } = result.data as CollectionResponse<PettyCash>;

                const pettyCash = items.find(
                    (item) => item.default && item.currency.currencyId === arg.currencyId,
                );

                if (pettyCash) {
                    return { data: pettyCash as PettyCash };
                }

                if (page < meta.last) {
                    return this.queryFn(
                        { ...arg, page: page + 1 },
                        _queryApi,
                        _extraOptions,
                        fetchWithBQ,
                    );
                }

                return { data: null };
            },
        }),
        getAllPettyCashes: build.query<PettyCashGetAllResponse, PettyCashGetAllParams>({
            async queryFn(arg, _queryApi, _extraOptions, fetchWithBQ) {
                const { accountId, records = [], page = 1 } = arg;
                const result = await fetchWithBQ({
                    url: `api/v1/accounting/${accountId}/petty_cashes`,
                    params: { page, perPage: 100 },
                });

                if (result.error) {
                    return { error: result.error as ArnoldBaseQueryError };
                }

                const { items, meta } = result.data as CollectionResponse<PettyCash>;

                records.push(...items);

                if (page < meta.last) {
                    return this.queryFn(
                        { ...arg, page: page + 1, records },
                        _queryApi,
                        _extraOptions,
                        fetchWithBQ,
                    );
                }

                return { data: records as PettyCash[] };
            },
        }),
    }),
});

export const { useGetPettyCashesQuery, useGetDefaultPettyCashQuery, useGetAllPettyCashesQuery } =
    pettyCashesApi;
