import { useDispatch, useSelector } from "react-redux";
import {
    processCommand,
    newActivity,
    endActivity,
    startSession,
    updateActivity,
    ActivityStatuses,
} from "../../redux/actions/dataEntering";

export default () => {
    const dispatch = useDispatch();
    const dataEntering = useSelector((state) => state.dataEntering);
    const activity = useSelector((state) => state.activity);

    const process = () => dispatch(processCommand(dataEntering, activity));
    const start = () => dispatch(startSession());
    const startActivity = () => dispatch(newActivity());
    const endAct = () => dispatch(endActivity({ activity }));
    const completeActivity = (entityId, entityType) =>
        dispatch(updateActivity({ entityId, entityType, status: ActivityStatuses.COMPLETED }));

    const { subscribed, loading, error, empty } = dataEntering;

    const canStartSession = !(loading || subscribed || empty || !!error);

    return {
        processCommand: process,
        start,
        startActivity,
        completeActivity,
        endActivity: endAct,
        canStartSession,
        ...dataEntering,
        activity,
    };
};
