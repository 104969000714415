import api from "../utils/api";
import { API_ROOT } from "../utils/constants";

const ENDPOINTS = {
    queuedDocumentsOverview: `${API_ROOT}statistics/queued_documents/overview`,
    queuedDocumentsDistribution: `${API_ROOT}statistics/queued_documents/distribution`,
    skippedUploadedDocumentsOverview: `${API_ROOT}statistics/skipped_documents/overview`,
    skippedUploadedDocumentsDistribution: `${API_ROOT}statistics/skipped_documents/distribution`,
    currentPerformance: `${API_ROOT}statistics/processing_performance/current`,
    yearsPerformance: `${API_ROOT}statistics/processing_performance/history/years`,
    weeksPerformance: `${API_ROOT}statistics/processing_performance/history/weeks`,
};

const PERFORMANCE_OVERVIEW_KEYS = ["actions", "documents", "accountingDocuments", "skips"];

const skippingPercentageFromRecord = ({ accountingDocuments, skips }) =>
    accountingDocuments <= 0 ? 0 : (100 * skips) / accountingDocuments;

const transformPerformanceYear = (year) => {
    let result = {
        ...Object.fromEntries(PERFORMANCE_OVERVIEW_KEYS.map((k) => [k, undefined])),
        adPerD: undefined,
        sPerD: undefined,
        sPerAd: undefined,
        skippingPercentage: undefined,
    };

    if (!year) return result;

    result = { ...result, ...year };

    const per = (a, b) => result[a] / result[b];

    result.adPerD = per("accountingDocuments", "documents");
    result.sPerD = per("skips", "documents");
    result.sPerAd = per("skips", "accountingDocuments");
    result.skippingPercentage = skippingPercentageFromRecord(result);

    return result;
};

const transformPerformanceCurrent = (current) => {
    let result = {
        ...Object.fromEntries(PERFORMANCE_OVERVIEW_KEYS.map((k) => [`${k}Speed`, undefined])),
        skippingPercentage: undefined,
    };

    if (!current || typeof current !== "object" || Array.isArray(current)) return {};

    result = { ...result, ...current };

    PERFORMANCE_OVERVIEW_KEYS.forEach((k) => {
        result[`${k}Speed`] = result[k] / 7;
    });

    result.skippingPercentage = skippingPercentageFromRecord(result);

    return result;
};

const Statistics = {
    api: {
        getQueuedDocumentsOverview: () => api.get({ url: ENDPOINTS.queuedDocumentsOverview }),
        getQueuedDocumentsDistribution: () =>
            api.get({ url: ENDPOINTS.queuedDocumentsDistribution }),
        getSkippedDocumentsOverview: () =>
            api.get({ url: ENDPOINTS.skippedUploadedDocumentsOverview }),
        getSkippedDocumentsDistribution: () =>
            api.get({ url: ENDPOINTS.skippedUploadedDocumentsDistribution }),
        getCurrentPerformance: () => api.get({ url: ENDPOINTS.currentPerformance }),
        getYearsPerformance: () => api.get({ url: ENDPOINTS.yearsPerformance }),
        getWeeksPerformance: () => api.get({ url: ENDPOINTS.weeksPerformance }),
    },
    compute: {
        totals: (years) => {
            if (!Array.isArray(years)) return {};

            const sums = years.reduce(
                (acc, y) =>
                    Object.fromEntries(PERFORMANCE_OVERVIEW_KEYS.map((k) => [k, y[k] + acc[k]])),
                Object.fromEntries(PERFORMANCE_OVERVIEW_KEYS.map((k) => [k, 0])),
            );

            return transformPerformanceYear(sums);
        },
        years: (years) => {
            if (!Array.isArray(years)) return [];

            return years.map(transformPerformanceYear);
        },
        current: (current) => transformPerformanceCurrent(current),
        characteristicsGroupByYear: (weeks) => {
            if (!Array.isArray(weeks)) return {};

            const byYears = weeks.reduce((years, record) => {
                const { year } = record;

                return { ...years, [year]: [...(years[year] || []), record] };
            }, {});

            const characteristics = (xs, k) =>
                xs.reduce(
                    ({ count, sum, min, max }, x) => {
                        const v = x[k];
                        return {
                            count: count + 1,
                            sum: sum + v,
                            min: Math.min(min, v),
                            max: Math.max(max, v),
                        };
                    },
                    { count: 0, sum: 0, min: Infinity, max: -Infinity },
                );

            return Object.fromEntries(
                Object.entries(byYears).map(([y, xs]) => {
                    const vs = xs.map((x) => ({
                        speed: x.documents / 7,
                        skippingPercentage: skippingPercentageFromRecord(x),
                    }));

                    const speed = characteristics(vs, "speed");
                    const skippingPercentage = characteristics(vs, "skippingPercentage");

                    return [
                        y,
                        {
                            speedMin: speed.min,
                            speedAvg: speed.sum / speed.count,
                            speedMax: speed.max,
                            skippingPercentageMin: skippingPercentage.min,
                            skippingPercentageAvg:
                                skippingPercentage.sum / skippingPercentage.count,
                            skippingPercentageMax: skippingPercentage.max,
                        },
                    ];
                }),
            );
        },
        weeksPerformance: (weeks) => {
            if (!Array.isArray(weeks)) return [];

            return weeks.map(transformPerformanceCurrent);
        },
    },
};

export default Statistics;
