import { all } from "redux-saga/effects";
import auth from "./auth";
import notifications from "./notifications";
import dataEntering from "./dataEntering";
import fetch from "./fetch";
import skippedUploadedDocuments from "./skippings";
import inactivitySagas from "./inactivity";
import accounts from "./accounts";
import ares from "./ares";

export default function* rootSaga() {
    yield all([
        ...auth,
        ...fetch,
        ...dataEntering,
        ...notifications,
        ...skippedUploadedDocuments,
        ...inactivitySagas,
        ...accounts,
        ...ares,
    ]);
}
